import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, InputGroup, Form, Button } from "react-bootstrap";
import axios from "axios";

import API_ROUTE from "../../utils/apiRoute";
const RequestModal = ({
  isShowing,
  toggle,
  userName,
  requestReservation,
  inputRef,
  selectRef,
}) => {
  const [reasons, setReasons] = useState([]);
  useEffect(() => {
    let didCancel = false;

    (async () => {
      await axios
        .get(`${API_ROUTE}reason`, {
          withCredentials: true,
        })
        .then((res) => {
          if (didCancel) return;
          setReasons(res.data.data.allReasons);
        });
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  const buildReasonsOptions = () => {
    const optionsArr = React.Children.toArray(
      reasons.map((val) => {
        return <option>{val.designation}</option>;
      })
    );
    return optionsArr;
  };
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal show={isShowing} onHide={toggle}>
            <Modal.Header closeButton>
              <Modal.Title>Solicitar Reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Nome
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  aria-label="Default"
                  disabled
                  defaultValue={userName}
                  aria-describedby="inputGroup-sizing-default"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Motivo</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  ref={selectRef}
                  as="select"
                  aria-label="With textarea"
                >
                  {buildReasonsOptions()}
                </Form.Control>
              </InputGroup>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Descrição</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  ref={inputRef}
                  as="textarea"
                  aria-label="With textarea"
                />
              </InputGroup>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  requestReservation();
                  toggle();
                }}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default RequestModal;
