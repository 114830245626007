import React, { useState, useRef, useEffect } from "react";
import {
  Navbar,
  Form,
  Nav,
  InputGroup,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import NavLink from "react-bootstrap/NavLink";
import { FaHome, FaInfoCircle, FaSearch, FaRegFileExcel } from "react-icons/fa";
import { MdNotificationsNone } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import "./navbar.styles.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import API_ROUTE from "../../utils/apiRoute";

const NavbarCustom = ({
  isAuthenticated,
  systemManager,
  spaceManager,
  employeeName,
  logout,
}) => {
  const [spaceName, setSpaceName] = useState("");
  const [allSpaces, setAllSpaces] = useState([]);
  const [shouldOpenSearchMenu, setShouldOpenSearchMenu] = useState(false);

  const searchBarRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    (async () => {
      await axios
        .get(`${API_ROUTE}space`, {
          withCredentials: true,
        })
        .then((res) => {
          if(didCancel) return
          setAllSpaces(res.data.data.allSpaces);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    return () => { didCancel = true };
  }, []);

  const goToUrl = (url) => {
    history.push(url);
  };

  const dropdownSearch = () => {
    if (
      shouldOpenSearchMenu &&
      searchBarRef.current &&
      searchBarRef.current.offsetWidth &&
      spaceName &&
      spaceName !== ""
    ) {
      const resultSpaces = allSpaces.filter((space) =>
        space.name.toLowerCase().includes(spaceName)
      );

      const spacesNameTag =
        resultSpaces.length > 0 ? (
          React.Children.toArray(
            resultSpaces.map((space) => (
              <div
                className="search-menu-item"
                onMouseDown={(evt) => {
                  evt.preventDefault(); // prevent to avoid on blur to get called and then re-render the UI
                }}
                onClick={() => {
                  goToUrl(
                    `/floor/${space.floorId}/area/${space.areaId}/spaceinfo/${space._id}`
                  );
                }}
              >
                {space.name}
              </div>
            ))
          )
        ) : (
          <div className="search-menu-item">Nenhum espaço foi encontrado.</div>
        );

      return (
        <div
          className="search-menu"
          style={{ width: searchBarRef.current.offsetWidth }}
        >
          {spacesNameTag}
        </div>
      );
    }
  };

  const handleChange = (evt) => {
    setSpaceName(evt.currentTarget.value);
  };

  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Navbar.Brand href="/">GESTÃO DE ESPAÇOS</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {isAuthenticated ? (
            <Nav.Link href="/home">
              <FaHome className="icon-left icon-purple" />
              Início
            </Nav.Link>
          ) : null}

          {systemManager || !isAuthenticated ? null : (
            <Nav.Link href="/notifications">
              <MdNotificationsNone className="icon-left icon-purple" />
              Notificações
            </Nav.Link>
          )}

          {isAuthenticated ? (
            <Nav.Link href="/findSpace">
              <FaSearch className="icon-left icon-purple" />
              Filtrar Espaços
            </Nav.Link>
          ) : null}

          {(systemManager && isAuthenticated) ||
          (spaceManager && isAuthenticated) ? (
            <NavDropdown title="Gestão">
              <NavDropdown.Item href="/manage-system" id="manageSystem">
                Gerir Sistema
              </NavDropdown.Item>

              <NavDropdown.Item href="/myspaces">
                Espaços Geridos por Mim
              </NavDropdown.Item>
              {isAuthenticated && systemManager ? (
                <>
                  <NavDropdown.Item
                    href="/delete-reservation"
                    id="deleteReservations"
                  >
                    Remover Reservas
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/permissions">
                    Gerir Permissões
                  </NavDropdown.Item>

                  <NavDropdown.Item href="/reservationReasons">
                    Gerir Motivos de Reservas
                  </NavDropdown.Item>
                </>
              ) : null}
            </NavDropdown>
          ) : null}

          {isAuthenticated && systemManager ? (
            <Nav.Link href="/info">
              <FaInfoCircle className="icon-left icon-purple" />
              Informações do Sistema
            </Nav.Link>
          ) : null}

          {isAuthenticated && spaceManager ? (
            <Nav.Link href="/spreadsheets">
              <FaRegFileExcel className="icon-left icon-purple" />
              Gerar Excel
            </Nav.Link>
          ) : null}
        </Nav>

        {isAuthenticated ? (
          <InputGroup
            ref={searchBarRef}
            style={{ width: "200px", marginRight: "30px" }}
          >
            <FormControl
              placeholder="Pesquisar espaço"
              aria-label="Nome do Espaço"
              aria-describedby="basic-addon1"
              onChange={handleChange}
              value={spaceName}
              onBlur={() => {
                setShouldOpenSearchMenu(false);
              }}
              onFocus={() => {
                setShouldOpenSearchMenu(true);
              }}
            />
            {dropdownSearch()}
          </InputGroup>
        ) : null}

        {isAuthenticated && (systemManager || spaceManager) ? (
          <Navbar.Text>
            {systemManager ? "Administrador do sistema" : "Gestor de Espaços"}
          </Navbar.Text>
        ) : null}

        <Form inline>
          {isAuthenticated ? (
            <NavLink className="navbar-username">
              {employeeName}
              <FiLogOut className="nav__logout" onClick={logout} />
            </NavLink>
          ) : (
            <Nav.Link href="/">Login</Nav.Link>
          )}
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarCustom;
