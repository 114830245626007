import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import useModal from "../../utils/useModal";
import API_ROUTE from "../../utils/apiRoute";
import { customStyles } from "../../utils/react-select.custom-styles";

const ManageSpaceManagersModal = ({
  isShowing,
  toggle,
  setActionFeedbackModalAction,
  setActionFeedbackModalTitleText,
  toggleActionFeedbackModal,
  isAddSpaceManager,
}) => {
  const {
    isShowing: isShowingConfirmationModal,
    toggle: toggleConfirmationModal,
  } = useModal();

  const [allSpaceManagers, setAllSpaceManagers] = useState([]);
  const [currentSpaceManager, setCurrentSpaceManager] = useState(null);
  const [newSpaceManager, setNewSpaceManager] = useState(null);

  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee/getAll/`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllEmployees = res.data.data.employees;

          const tmpAllSpaceManagers = tmpAllEmployees.filter((employee) => {
            if (employee.role === "space-manager") return true;
          });
          const allEmployeesOptions = tmpAllSpaceManagers.map((employee) => {
            return {
              value: employee._id,
              label: employee.name,
              role: employee.role,
            };
          });
          console.log(allEmployeesOptions);
          setAllSpaceManagers(allEmployeesOptions);
        });
    })();
  }, []);

  const handleChangeCurrentSpaceManager = (selected) => {
    setCurrentSpaceManager(selected);
  };

  const handleChangeNewSpaceManager = (selected) => {
    setNewSpaceManager(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    toggleConfirmationModal();
  };

  const handleSubmitRequest = () => {
    (async () => {
      await axios
        .patch(
          `${API_ROUTE}space/updateSpaceManager`,
          {
            currentSpaceManager,
            newSpaceManager,
            operationType: isAddSpaceManager ? "add" : "remove",
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setActionFeedbackModalTitleText("Gestor adicionado com sucesso!");
          setActionFeedbackModalAction(true);
          toggleActionFeedbackModal();
          toggle();
        })
        .catch((err) => {
          if (err.response.data.message) {
            setActionFeedbackModalTitleText(err.response.data.message);
          } else {
            setActionFeedbackModalTitleText(
              "Algo inesperado aconteceu, tente novamente mais tarde!"
            );
          }
          setActionFeedbackModalAction(false);
          toggleActionFeedbackModal();
          toggle();
        });
    })();
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isAddSpaceManager
                    ? "Adicionar gestor de espaço"
                    : "Remover gestor de espaço"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    Selecione o atual gestor do(s) espaço(s)
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um utilizador"
                    options={allSpaceManagers}
                    className="react-select"
                    value={currentSpaceManager}
                    onChange={handleChangeCurrentSpaceManager}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    {isAddSpaceManager
                      ? "Selecione o novo gestor de espaço"
                      : "Selecione o gestor a ser removido"}
                  </Form.Label>
                  <Select
                    placeholder="Selecione uma permissão"
                    options={allSpaceManagers}
                    className="react-select"
                    value={newSpaceManager}
                    onChange={handleChangeNewSpaceManager}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  disabled={
                    currentSpaceManager &&
                    newSpaceManager &&
                    currentSpaceManager !== newSpaceManager
                      ? false
                      : true
                  }
                >
                  {isAddSpaceManager ? "Adicionar" : "Remover"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={handleSubmitRequest}
            titleText={
              isAddSpaceManager
                ? "Adicionar permissão de utilizador"
                : "Remover permissão de utilizador"
            }
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ManageSpaceManagersModal;
