import React from "react";

import "./specific-area.style.scss";
import API_ROUTE from "../../utils/apiRoute";

class SpecificArea extends React.Component {
  state = {
    style: "hover_group",
    coordsRefIsActive: {},
    isSpaceClicked: false,
  };

  componentDidMount() {
    this.props.coordinates.forEach((coords) => {
      this.setState((prevState) => ({
        coordsRefIsActive: {
          ...prevState.coordsRefIsActive,
          [coords.id]: false,
        },
      }));
    });
  }

  render() {
    const {
      showAllSelectableArea,
      imgWidth,
      imgHeight,
      img,
      bookable,
      coordinates,
      isFloor,
    } = this.props;

    // METHOD THAT HIGHLIGHTS SPECIFIC AREAS
    const toggleStyle = (e, coords) => {
      // set all actives to false so that there's only one space active!
      let tmpCoordsObj = { ...this.state.coordsRefIsActive };

      for (var key in tmpCoordsObj) {
        tmpCoordsObj[key] = false;
      }
      this.setState((state) => {
        return {
          coordsRefIsActive: tmpCoordsObj,
        };
      });

      // toggle the clicked area state
      this.setState(
        (prevState) => ({
          coordsRefIsActive: {
            ...prevState.coordsRefIsActive,
            [coords.id]: !this.state.coordsRefIsActive[coords.id],
          },
        }),
        () => {
          // set the selected id
          this.state.coordsRefIsActive[coords.id]
            ? this.props.setIdFunc(coords.id)
            : this.props.removeIdFunc();

          // toggle the isSpaceClicked variable ( to control the mouseOut event listener)
          this.setState({
            isSpaceClicked: this.state.coordsRefIsActive[coords.id]
              ? true
              : false,
          });
        }
      );

      e.preventDefault();
    };

    // this prevents images getting too big
    const svgStyle = () => {
      if (imgHeight - imgWidth > imgWidth) return { height: "45vh" };

      return { width: "45vw" };
    };

    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${imgWidth} ${imgHeight}`}
          style={svgStyle()}
        >
          <image
            width={imgWidth}
            height={imgHeight}
            xlinkHref={`${API_ROUTE}images/${img}`}
          ></image>
          {React.Children.toArray(
            coordinates.map((val) => {
              // test if the space is bookable
              return !bookable ? (
                <g
                  className={`${this.state.style} ${
                    this.props.showAllSelectableArea ? "allSelected" : null
                  } ${
                    this.state.coordsRefIsActive[val.id]
                      ? "active-selected-area"
                      : null
                  }`}
                  opacity="0"
                  onMouseOver={() => {
                    // just set the id if a space was NOT clicked
                    if (!this.state.isSpaceClicked) {
                      this.props.setIdFunc(val.id);
                    }
                  }}
                  onMouseOut={() => {
                    // just remove the id if a space was NOT clicked
                    if (!this.state.isSpaceClicked) {
                      this.props.removeIdFunc();
                    }
                  }}
                  onClick={(e) => {
                    toggleStyle(e, val);
                  }}
                  onDoubleClick={(e) => {
                    if (this.props.id === null) {
                      toggleStyle(e, val);
                    }
                    this.props.goToSelected();
                  }}
                >
                  <a href="/">
                    <path
                      className={`pathSpecificArea ${
                        showAllSelectableArea &&
                        !this.state.coordsRefIsActive[val.id]
                          ? "showAllSelectable"
                          : null
                      }`}
                      stroke="black"
                      strokeWidth="1"
                      opacity="0.5"
                      d={val.coords}
                    />
                  </a>
                </g>
              ) : (
                <g
                  opacity="0"
                  className={`${this.state.style}`}
                  onClick={toggleStyle}
                >
                  <a href="/">
                    <path
                      className="not-bookable pathSpecificArea"
                      stroke="black"
                      strokeWidth="1"
                      opacity="0.5"
                      d={val.coords}
                    />
                  </a>
                </g>
              );
            })
          )}
        </svg>
      </div>
    );
  }
}

export default SpecificArea;
