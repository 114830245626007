import React from "react";
import ReactDOM from "react-dom";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { Modal } from "react-bootstrap";

const ActionFeedbackModal = ({
  isShowing,
  toggle,
  titleText,
  isActionCompleted,
}) => {
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              window.location.reload();
              toggle();
            }}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {isActionCompleted ? (
                  <FaRegCheckCircle
                    style={{ height: "15rem", width: "10rem", fill: "green" }}
                  />
                ) : (
                  <FaRegTimesCircle
                    style={{ height: "15rem", width: "10rem", fill: "red" }}
                  />
                )}
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>,

        document.body
      )
    : null;
};

export default ActionFeedbackModal;
