import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import useModal from "../../utils/useModal";
import DeleteReservationModal from "../../components/delete-reservation-modal/delete-reservation-modal.component";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal.component";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";

import API_ROUTE from "../../utils/apiRoute";

const DeleteReservation = () => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const {
    isShowing: isShowingDeleteReservationModal,
    toggle: toggleDeleteReservationModal,
  } = useModal();

  const {
    isShowing: isShowingConfirmationModal,
    toggle: toggleConfirmationModal,
  } = useModal();

  const {
    isShowing: isShowingFeedbackModal,
    toggle: toggleFeedbackModal,
  } = useModal();

  const deleteReservation = () => {
    (async () => {
      await axios
        .delete(`${API_ROUTE}reservation/`, {
          withCredentials: true,
          params: {
            dateStart,
            dateEnd,
          },
        })
        .then((res) => {
          toggleConfirmationModal();
          toggleDeleteReservationModal();
          setIsActionCompleted(true);
          toggleFeedbackModal();
        })
        .catch((err) => {
          setIsActionCompleted(false);
          console.log(err.response);
        });
    })();
  };

  return (
    <div>
      <Button
        variant="outline-info"
        style={{ marginTop: "10%" }}
        onClick={toggleDeleteReservationModal}
      >
        Remover Reservas
      </Button>
      <DeleteReservationModal
        toggle={toggleDeleteReservationModal}
        isShowing={isShowingDeleteReservationModal}
        toggleConfirmationModal={toggleConfirmationModal}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
      />
      <ConfirmationModal
        isShowing={isShowingConfirmationModal}
        toggle={toggleConfirmationModal}
        handleConfirmation={deleteReservation}
        titleText={"Remover Reservas"}
      />

      <ActionFeedbackModal
        isShowing={isShowingFeedbackModal}
        toggle={toggleFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={"Reservas Removidas com Sucesso!"}
      />
    </div>
  );
};

export default DeleteReservation;
