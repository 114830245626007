import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, Button, Row, Col, InputGroup } from "react-bootstrap";

const DeleteReservationModal = ({
  isShowing,
  toggle,
  toggleConfirmationModal,
  dateStart,
  dateEnd,
  setDateStart,
  setDateEnd,
}) => {
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);

  useEffect(() => {
    if (dateStart && dateEnd) {
      if (new Date(dateStart).getTime() <= new Date(dateEnd).getTime()) {
        setIsConfirmBtnDisabled(false);
      } else {
        setIsConfirmBtnDisabled(true);
      }
    } else {
      setIsConfirmBtnDisabled(true);
    }
  }, [dateStart, dateEnd]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remover reservas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ fontWeight: "500" }}>
                  Selecione um intervalo de tempo para remover as reservas.
                </p>
                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Data de Início</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="date"
                        value={dateStart}
                        onChange={(evt) => {
                          setDateStart(evt.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Data de Fim</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="date"
                        value={dateEnd}
                        onChange={(evt) => {
                          setDateEnd(evt.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  toggleConfirmationModal();
                  // deleteReservation();
                }}
                disabled={isConfirmBtnDisabled}
              >
                Remover Reservas
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>,

        document.body
      )
    : null;
};

export default DeleteReservationModal;
