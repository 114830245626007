import React, { useState } from "react";

import useModal from '../../utils/useModal'
import SelectEditSpace from "./select-edit-space.component";
import EditSpaceModal from "./edit-space-modal.component";
import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";

import ListFloorAreaSpaceModal from "../list-floor-area-space/list-floor-area-space-modal.component";

const EditSpace = ({
  isShowing,
  setIsEdit,
  toggle,
  isEdit,
  spaceSelectType,
}) => {
  const { isShowing: isShowingEditSpaceModal, toggle: toggleEditSpaceModal } = useModal();
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();
  const { isShowing: isShowingListFloorAreaSpaceModal, toggle: toggleListFloorAreaSpaceModal } = useModal();


  const [selectedArea, setSelectedArea] = useState(null);

  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [spaceId, setSpaceId] = useState(null);
  const [titleText, setTitleText] = useState(null);

  return (
    <>
      <SelectEditSpace
        isShowing={isShowing}
        setSpaceId={setSpaceId}
        spaceId={spaceId}
        toggle={toggle}
        toggleEditSpaceModal={toggleEditSpaceModal}
        isEdit={isEdit}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
        spaceSelectType={spaceSelectType}
        toggleListSpace={toggleListFloorAreaSpaceModal}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
      />
      <ListFloorAreaSpaceModal
        toggle={toggleListFloorAreaSpaceModal}
        setIsEdit={setIsEdit}
        isShowing={isShowingListFloorAreaSpaceModal}
        setDocumentId={setSpaceId}
        setIsActionCompleted={setIsActionCompleted}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        toggleEdit={toggleEditSpaceModal}
        listType="space"
        documentId={selectedArea ? selectedArea.value : null}
      />
      <EditSpaceModal
        isShowing={isShowingEditSpaceModal}
        toggle={toggleEditSpaceModal}
        spaceId={spaceId}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
      />
      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={titleText}
      />
    </>
  );
};

export default EditSpace;
