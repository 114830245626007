import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CreateAreaModal from "../../components/manage-area-modal/create-area-modal/create-area-modal.component";
import CreateSpaceModal from "../../components/manage-space-modal/create-space-modal/create-space-modal.component";
import EditFloor from "../../components/edit-floor/edit-floor.component";
import EditArea from "../../components/edit-area/edit-area.component";
import EditSpace from "../../components/edit-space/edit-space.component";

import useModal from '../../utils/useModal'

import CrudSpaceType from "../../components/crud-space-type/crud-space-type.component";

import "./manage-system.styles.scss";

const ManageSystem = () => {
  const { isShowing: isShowingCreateAreaModal, toggle: toggleCreateAreaModal } = useModal();
  const { isShowing: isShowingCreateSpaceModal, toggle: toggleCreateSpaceModal } = useModal();
  const { isShowing: isShowingEditFloorModal, toggle: toggleEditFloorModal } = useModal();
  const { isShowing: isShowingEditAreaModal, toggle: toggleEditAreaModal } = useModal();
  const { isShowing: isShowingEditSpaceModal, toggle: toggleEditSpaceModal } = useModal();
  const { isShowing: isShowingListArea, toggle: toggleListArea } = useModal();
  const { isShowing: isShowingListFloor, toggle: toggleListFloor } = useModal();

  const [selectType, setSelectType] = useState("edit");

  const [isArea, setIsArea] = useState(false);

  const [isEdit, setIsEdit] = useState(true);

  return (
    <div className="manageSystemWrapper">
      <div className="buttonPanel">
        <h5>Gerir Pisos</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsArea(false);
            toggleCreateAreaModal();
          }}
        >
          Criar Piso
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsArea(false);
            toggleListFloor();
          }}
        >
          Listar Pisos
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            toggleEditFloorModal();
            setIsEdit(true);
          }}
        >
          Editar Piso
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            toggleEditFloorModal();
            setIsEdit(false);
          }}
        >
          Remover Piso
        </Button>
      </div>

      <div className="buttonPanel">
        <h5>Gerir Áreas</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsArea(true);
            toggleCreateAreaModal();
          }}
        >
          Criar Área
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("list");
            toggleEditAreaModal();
          }}
        >
          Listar Áreas
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("edit");
            toggleEditAreaModal();
          }}
        >
          Editar Área
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("delete");
            toggleEditAreaModal();
          }}
        >
          Remover Área
        </Button>
      </div>

      <div className="buttonPanel">
        <h5>Gerir Espaços</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            toggleCreateSpaceModal();
          }}
        >
          Criar Espaço
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("list");
            toggleEditSpaceModal();
          }}
        >
          Listar Espaços
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("edit");
            setIsEdit(true);
            toggleEditSpaceModal();
          }}
        >
          Editar Espaço
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setSelectType("delete");
            setIsEdit(false);
            toggleEditSpaceModal();
          }}
        >
          Remover Espaço
        </Button>
      </div>

      <div className="buttonPanel">
        <CrudSpaceType />
      </div>

      <CreateAreaModal isShowing={isShowingCreateAreaModal} toggle={toggleCreateAreaModal} isArea={isArea} />
      <CreateSpaceModal isShowing={isShowingCreateSpaceModal} toggle={toggleCreateSpaceModal} />
      <EditFloor
        toggle={toggleEditFloorModal}
        isShowing={isShowingEditFloorModal}
        isEdit={isEdit}
        isShowingListFloor={isShowingListFloor}
        toggleListFloor={toggleListFloor}
        setIsEdit={setIsEdit}
      />
      <EditArea
        setIsEdit={setIsEdit}
        areaSelectType={selectType}
        toggle={toggleEditAreaModal}
        isShowing={isShowingEditAreaModal}
        toggleListArea={toggleListArea}
        isShowingListArea={isShowingListArea}
      />
      <EditSpace
        setIsEdit={setIsEdit}
        toggle={toggleEditSpaceModal}
        isShowing={isShowingEditSpaceModal}
        isEdit={isEdit}
        spaceSelectType={selectType}
      />
    </div>
  );
};

export default ManageSystem;
