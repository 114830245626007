import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";

const CreateReservationReasonModal = ({
  isShowing,
  toggle,
  setTitleText,
  reasonId,
  isEdit,
  toggleActionFeedback,
  setIsActionCompleted,
  reasonDesignation,
  setReasonDesignation,
}) => {
  const [validated, setValidated] = useState(false);

  const handleReasonDesignationChange = (evt) => {
    setReasonDesignation(evt.currentTarget.value);
  };

  // default value if it is edit
  useEffect(() => {
    if (reasonId) {
      if (isEdit) {
        (async () => {
          await axios
            .get(`${API_ROUTE}reason/${reasonId}`, {
              withCredentials: true,
            })
            .then((res) => {
              console.log("New reason");
              setReasonDesignation(res.data.data.reason.designation);
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
            });
        })();
      }
    }
  }, [reasonId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (isEdit) {
        const dataToSend = {
          newReasonDesignation: reasonDesignation,
        };
        (async () => {
          await axios
            .patch(`${API_ROUTE}reason/${reasonId}`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Motivo de Reserva Alterado com Sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      } else {
        const dataToSend = {
          designation: reasonDesignation,
        };
        (async () => {
          await axios
            .post(`${API_ROUTE}reason`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Motivo de reserva criado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      }
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit
                    ? "Editar Motivo de Reserva"
                    : "Criar Motivo De Reserva"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Motivo</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Motivo da reserva"
                    value={reasonDesignation}
                    onChange={handleReasonDesignationChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    O motivo da reserva precisa ser definido!
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CreateReservationReasonModal;
