import React from "react";
import { Dropdown } from "react-bootstrap";
import "./breadcrumb.styles.scss";

const CustomToggle = React.forwardRef(({ children: toggleItemName, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="breadcrumb-text"
  >
    {toggleItemName}
    <span className="breadcrumb-icon">&#x25bc;</span>
  </a>
));


// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children)}
        </ul>
      </div>
    );
  }
);

const BreadcrumbSeparator = ({ children, ...props }) => (
  <div className="breadcrumb-separator" {...props}>
    {children}
  </div>
);


const addBreadcrumbseparatorBetweenBreadcrumb = (breadcrumbComponentsArray) => {
  const lastIndex = breadcrumbComponentsArray.length - 1;

  return breadcrumbComponentsArray = breadcrumbComponentsArray.reduce((acc, child, index) => {
    const notLast = index < lastIndex;
    if (notLast) {
      acc.push(
        child,
        <BreadcrumbSeparator key={`separator_${index}`}>
          {" "}/{" "}
        </BreadcrumbSeparator>
      );
    } else {
      acc.push(child);
    }
    return acc;
  }, []);
}

const BreadCrumb = ({ items: data, history }) => {
  let breadcrumbComponentsArray = React.Children.toArray(
    data.map(({ name, isToggle, items, isActive: isNotClickable, links }) => {
      return isToggle ? (
        <Dropdown>
          <Dropdown.Toggle
            as={CustomToggle}
            id="dropdown-custom-components"
            className="breadcrumb-text"
          >
            {name}
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {React.Children.toArray(
              items.map((dropdownItemName, idx) => {
                return (
                  <Dropdown.Item
                    eventKey={idx}
                    onClick={() => {
                      history.replace(links[idx]);
                      history.go();
                    }}
                  >
                    {dropdownItemName}
                  </Dropdown.Item>
                );
              })
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div
          className={isNotClickable ? null : "breadcrumb-text"}
          onClick={() => {
            if(isNotClickable) return;
            history.replace(links);
            history.go();
          }}
        >
          {name}
        </div>
      );
    })
  );

  breadcrumbComponentsArray = addBreadcrumbseparatorBetweenBreadcrumb(breadcrumbComponentsArray);

  return <div className="breadcrumb-list">{breadcrumbComponentsArray}</div>;
};

export default BreadCrumb;
