import React, { useState, useEffect, useCallback } from "react";
import { Toast, Spinner, Popover, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { IoMdCheckmark } from "react-icons/io";
import { FaPencilAlt, FaRegQuestionCircle, FaEye } from "react-icons/fa";
import RefuseReservationModal from "../../components/refuse-reservation-modal/refuse-reservation-modal.component";
import EditReservationModal from "../../components/edit-reservation-modal/edit-reservation-modal.component";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal.component";
import "./notification.styles.scss";

import { useDispatch } from "react-redux";

import API_ROUTE from "../../utils/apiRoute";
import useModal from "../../utils/useModal";

const NotificationPage = ({ history }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [reservationData, setReservationData] = useState([]);
  const [arrayOfNotifications, setArrayOfNotifications] = useState([]);

  const inputRef = useState(React.createRef())[0];

  const [clickedReservData, setClickedReservData] = useState({});
  const [selectedSort, setSelectedSort] = useState("");

  const { isShowing: isShowingRefuseReservationModal, toggle: toggleRefuseReservationModal } = useModal();
  const { isShowing: isShowingEditReservationModal, toggle: toggleEditReservationModal } = useModal();
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [filterType, setFilterType] = useState("requester");
  const [inputFilterValue, setInputFilterValue] = useState("");

  const goToTimetable = useCallback((reservationData) => {
    const dateObj = new Date(reservationData.timeStart);
    const val = dateObj;

    let day = val.getDay().toString();
    if (val.getDay().toString() === "0") {
      day = "7";
    }
    const strDay = `${val.getFullYear().toString()}-${(
      val.getMonth() + 1
    ).toString()}-${val.getDate().toString()}-${day}`;

    dispatch({ type: "SET_DATE", payload: [strDay] });

    history.push(
      `/floor/${reservationData.floorId}/area/${reservationData.areaId}/timetable/${reservationData.spaceId}?dates=${dateObj}`
    );
  }, []);

  const handleRequest = async () => {
    clickedReservData.status = "reservado";
    clickedReservData.decisionDate = new Date();
    await axios
      .put(
        `${API_ROUTE}reservation`,
        {
          clickedReservData,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        window.location.reload();
      });
  };

  const createPopover = (msg) => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Informação</Popover.Title>
      <Popover.Content>{msg}</Popover.Content>
    </Popover>
  );

  const declineRequest = async () => {
    clickedReservData.decisionDate = new Date();
    clickedReservData.status = "rejeitado";
    clickedReservData.analysisJustification = inputRef.current.value;
    await axios
      .put(
        `${API_ROUTE}reservation`,
        {
          clickedReservData,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        window.location.reload();
      });
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}reservation`, {
          withCredentials: true,
        })
        .then((res) => {
          setReservationData(res.data.data.reservationData);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHoursAndMinutes = (date) => {
    const dateObject = new Date(date);

    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();

    return parseFloat(`${hours}.${minutes}`);
  };

  const formatHour = (time) => {
    let formattedTime;
    formattedTime = new Date(time).toUTCString().split(" ")[4].split(":");
    formattedTime.pop();

    return formattedTime[0] + ":" + formattedTime[1];
  };

  const createToasts = (unformatedReservationData) => {
    const tmpArr = React.Children.toArray(
      unformatedReservationData.map((val, i) => {
        if (val.status === "em análise") {
          let reservationConflict = false;
          let yellowReservationConflict = false; // when there is a request to a time already reserved

          const start1 = getHoursAndMinutes(val.timeStart);
          const end1 = getHoursAndMinutes(val.timeEnd);

          // day month and year must be the same to compare
          const date1 = new Date(val.timeStart);
          date1.setHours(0, 0, 0, 0);

          unformatedReservationData.forEach((element, idx) => {
            const date2 = new Date(element.timeStart);
            date2.setHours(0, 0, 0, 0);
            if (
              i !== idx &&
              element.spaceId === val.spaceId &&
              date1.getTime() === date2.getTime()
            ) {
              const start2 = getHoursAndMinutes(element.timeStart);
              const end2 = getHoursAndMinutes(element.timeEnd);

              // check conflicts based on the hours
              if (
                (start1 >= start2 && start1 < end2) ||
                (start2 >= start1 && start2 < end1)
              ) {
                if (element.status === "reservado") {
                  yellowReservationConflict = true;
                } else if (element.status === "em análise") {
                  reservationConflict = true;
                }
              }
            }
          });

          let elapsedTime = Math.abs(Date.now() - new Date(val.requestDate));
          if (elapsedTime / 1000 / 60 / 60 > 1) {
            elapsedTime =
              parseInt(elapsedTime / 1000 / 60 / 60).toString() +
              `${
                parseInt(elapsedTime / 1000 / 60 / 60) === 1
                  ? " hora atrás"
                  : " horas atrás"
              }`;
          } else if (elapsedTime / 1000 / 60 < 1) {
            elapsedTime =
              parseInt(elapsedTime / 1000).toString() + ` segundos atrás`;
          } else {
            elapsedTime =
              parseInt(elapsedTime / 1000 / 60).toString() +
              `${
                parseInt(elapsedTime / 1000 / 60) === 1
                  ? " minuto atrás"
                  : " minutos atrás"
              }`;
          }

          const formattedTimeStart = formatHour(val.timeStart);

          const formattedTimeEnd = formatHour(val.timeEnd);

          return (
            <Toast
              onClose={() => {
                setClickedReservData(val);
                toggleRefuseReservationModal();
              }}
              className={
                yellowReservationConflict
                  ? "notification-toast-yellow"
                  : reservationConflict
                  ? "notification-toast-red"
                  : "notification-toast"
              }
            >
              <Toast.Header className="notification-toast--header">
                <strong className="mr-auto">{val.spaceName}</strong>
                <small>{elapsedTime}</small>
                {yellowReservationConflict ? (
                  <FaEye
                    className="icon-notification"
                    onClick={() => goToTimetable(val)}
                  />
                ) : null}
                <OverlayTrigger
                  trigger="click"
                  rootClose={true}
                  placement="top"
                  overlay={createPopover(
                    yellowReservationConflict
                      ? "Esta reserva não pode ser aceita pois já existe uma reserva aceita neste período."
                      : reservationConflict
                      ? "Esta reserva possui conflito com outra(s) reserva(s)."
                      : "Esta reserva não possui nenhum conflito com outras reservas."
                  )}
                >
                  <FaRegQuestionCircle className="icon-notification" />
                </OverlayTrigger>
                {yellowReservationConflict ? null : (
                  <IoMdCheckmark
                    className="icon-notification icon-check"
                    onClick={() => {
                      setClickedReservData(val);
                      toggleConfirmationModal();
                    }}
                  />
                )}

                <FaPencilAlt
                  className="icon-notification icon-gray"
                  onClick={() => {
                    setClickedReservData(val);
                    toggleEditReservationModal();
                  }}
                />
              </Toast.Header>
              <Toast.Body>
                <strong>Solicitante:</strong> {val.requester}
                <br />
                <strong>Motivo:</strong> {val.reason}
                <br />
                <strong>Descrição:</strong> {val.description}
                <br />
                <strong>Data: </strong>
                {new Date(val.timeStart).toLocaleDateString()} <br />
                <strong>Período de Início: </strong>
                {formattedTimeStart}
                <br />
                <strong>Período de Fim: </strong> &nbsp;&nbsp;&nbsp;
                {formattedTimeEnd}
                <br />
              </Toast.Body>
            </Toast>
          );
        }
      })
    );

    setArrayOfNotifications(tmpArr);
    setIsLoading(false);
  };

  useEffect(() => {
    createToasts(reservationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationData]);

  useEffect(() => {
    if (selectedSort === "byName") {
      // sort by Name
      reservationData.sort((a, b) =>
        a.requester.localeCompare(b.requester, { sensitivity: "base" })
      );
    } else if (selectedSort === "older") {
      // sort by older to newer
      reservationData.sort(
        (a, b) =>
          new Date(new Date(a.requestDate)) - new Date(new Date(b.requestDate))
      );
    } else if (selectedSort === "recents") {
      // sort by newer to older
      reservationData.sort(
        (a, b) =>
          new Date(new Date(b.requestDate)) - new Date(new Date(a.requestDate))
      );
    }
    createToasts(reservationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort]);

  const spinn = <Spinner animation="border" />;

  const handleFilter = (evt) => {
    const value = evt.target.value;
    setInputFilterValue(value);
  };

  useEffect(() => {
    const filteredReservation = reservationData.filter((reservation) =>
      reservation[filterType]
        .toLowerCase()
        .includes(inputFilterValue.toLowerCase())
    );
    createToasts(filteredReservation);
  }, [inputFilterValue]);

  return (
    <div className="notification-container">
      <form>
        <h6>Filtrar Por: </h6>
        <input
          type="text"
          value={inputFilterValue}
          placeholder="Espaço / Solicitante"
          onChange={handleFilter}
          className="filter-reservation-input-name"
        />
        <fieldset id="filterGroup">
          <div className="radiobtn">
            <input
              type="radio"
              value="requester"
              name="filterGroup"
              checked={filterType === "requester"}
            />
            <label htmlFor="older" onClick={() => setFilterType("requester")}>
              Solicitante
            </label>
          </div>
          <div className="radiobtn">
            <input
              type="radio"
              value="space"
              name="filterGroup"
              checked={filterType === "spaceName"}
            />
            <label htmlFor="older" onClick={() => setFilterType("spaceName")}>
              Espaço
            </label>
          </div>
        </fieldset>
        <h6>Ordenar: </h6>
        <fieldset id="sortGroup">
          <div className="radiobtn">
            <input
              type="radio"
              value="older"
              name="sortGroup"
              checked={selectedSort === "older"}
            />
            <label htmlFor="older" onClick={() => setSelectedSort("older")}>
              Mais Antigos
            </label>
          </div>

          <div className="radiobtn">
            <input
              type="radio"
              value="recents"
              name="sortGroup"
              checked={selectedSort === "recents"}
            />
            <label htmlFor="recents" onClick={() => setSelectedSort("recents")}>
              Mais Recentes
            </label>
          </div>

          <div className="radiobtn">
            <input
              type="radio"
              value="byName"
              name="sortGroup"
              checked={selectedSort === "byName"}
            />
            <label htmlFor="byDate" onClick={() => setSelectedSort("byName")}>
              Por Nome
            </label>
          </div>
        </fieldset>
      </form>

      <div className="notification-grid">
        {isLoading ? spinn : null}
        {arrayOfNotifications.length > 0
          ? arrayOfNotifications
          : "Nenhuma solicitação pendente."}
      </div>

      <RefuseReservationModal
        isShowing={isShowingRefuseReservationModal}
        toggle={toggleRefuseReservationModal}
        declineRequest={declineRequest}
        inputRef={inputRef}
      />

      <EditReservationModal
        isShowing={isShowingEditReservationModal}
        toggle={toggleEditReservationModal}
        reservationData={clickedReservData}
      />

      <ConfirmationModal
        isShowing={isShowingConfirmationModal}
        toggle={toggleConfirmationModal}
        handleConfirmation={handleRequest}
        titleText={"Aceitar Pedido de Reserva"}
      />
    </div>
  );
};

export default NotificationPage;
