import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Modal,
  InputGroup,
  Form,
  Button,
  Row,
  Col,
  Container,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import API_ROUTE from "../../utils/apiRoute";
import { GoAlert, GoThumbsup } from "react-icons/go";
import axios from "axios";
import Select, { components } from "react-select";

import TextModal from "../text-modal/text-modal.component";
import useModal from "../../utils/useModal";
import "./edit-reservation-modal.styles.scss";
import { isFirstStringTimeGreaterThanSecond } from "../../utils/time";

const EditReservationModal = ({ isShowing, toggle, reservationData }) => {
  const { isShowing: isShowingTextModal, toggle: toggleTextModal } = useModal();

  const [currentSpaceData, setCurrentSpaceData] = useState();

  const dateTimeStart = new Date(reservationData.timeStart);
  const [timeDate, setTimeStart] = useState();
  const [hourStart, setHourStart] = useState();

  const dateTimeEnd = new Date(reservationData.timeEnd);
  const [hourEnd, setHourEnd] = useState();

  const [spaces, setSpaces] = useState([]);

  const [reason, setReasonName] = useState("");
  const [allReasons, setAllReasons] = useState([]);

  const [spacesOptions, setSpacesOptions] = useState([]);

  const [spaceId, setSpaceId] = useState(null);

  const handleSelectSpace = (space) => {
    setSpaceId(space.value);
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {props.data.label}
            <div className="">
              <Badge
                fill="true"
                variant={props.data.isSystemSuggestion ? "success" : "warning"}
              >
                capacidade: {props.data.capacity}
              </Badge>
              {props.data.hasPendentRequest ? (
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="in" id="tooltip-top">
                      Este espaço possui uma reserva pendente neste horário.
                    </Tooltip>
                  }
                >
                  <GoAlert style={{ paddingLeft: "3%" }} />
                </OverlayTrigger>
              ) : null}

              {props.data.isSystemSuggestion ? (
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="in" id="tooltip-top">
                      Este espaço possui o mesmo tipo e capacidade maior ou
                      igual.
                    </Tooltip>
                  }
                >
                  <GoThumbsup style={{ paddingLeft: "3%" }} />
                </OverlayTrigger>
              ) : null}
            </div>
          </div>
        </components.Option>
      </div>
    );
  };

  // used to display message validation, if the hourEnd isn't greater than hourStart
  const [isInputTimeNotValid, setIsInputTimeNotValid] = useState(false);

  useEffect(() => {
    // if there's one input missing then do not validate
    if (!timeDate || !hourStart || !hourEnd || !reservationData) return;

    let timeStart = new Date();
    timeStart.setUTCFullYear(timeDate.split("-")[0]);
    timeStart.setUTCMonth(parseInt(timeDate.split("-")[1]) - 1);
    timeStart.setUTCDate(timeDate.split("-")[2]);
    timeStart.setUTCHours(hourStart.split(":")[0]);
    timeStart.setUTCMinutes(hourStart.split(":")[1]);
    timeStart.setUTCSeconds(0);
    timeStart.setUTCMilliseconds(0);

    let timeEnd = new Date();
    timeEnd.setUTCFullYear(timeDate.split("-")[0]);
    timeEnd.setUTCMonth(parseInt(timeDate.split("-")[1]) - 1);

    // is necessary to increase the day number in case the hour end is midnight
    let dayNumber =
      parseInt(hourEnd.split(":")[0]) === 0
        ? parseInt(timeDate.split("-")[2]) + 1
        : parseInt(timeDate.split("-")[2]);
    timeEnd.setUTCDate(dayNumber);
    timeEnd.setUTCHours(hourEnd.split(":")[0]);
    timeEnd.setUTCMinutes(hourEnd.split(":")[1]);
    timeEnd.setUTCSeconds(0);
    timeEnd.setUTCMilliseconds(0);

    const timeInterval = { timeStart, timeEnd };

    (async () => {
      if (
        !isNaN(Date.parse(timeInterval.timeStart)) ||
        !isNaN(Date.parse(timeInterval.timeStart))
      ) {
        await axios
          .get(`${API_ROUTE}space/time/${JSON.stringify(timeInterval)}`, {
            withCredentials: true,
            params: {
              reservationId: reservationData._id,
            },
          })
          .then((res) => {
            setSpaces(res.data.data.freeSpaces);
          })
          .catch((err) => {
            alert(err);
          });
      }
    })();
  }, [timeDate, hourStart, hourEnd]);

  const editReservation = async () => {
    const tmpTimeStart = new Date(
      Date.UTC(
        timeDate.split("-")[0],
        parseInt(timeDate.split("-")[1]) - 1,
        timeDate.split("-")[2],
        hourStart.split(":")[0],
        hourStart.split(":")[1]
      )
    );
    const tmpTimeEnd = new Date(
      Date.UTC(
        timeDate.split("-")[0],
        parseInt(timeDate.split("-")[1]) - 1,
        timeDate.split("-")[2],
        hourEnd.split(":")[0],
        hourEnd.split(":")[1]
      )
    );

    reservationData.status = "reservado";
    reservationData.spaceId = spaceId;

    reservationData.timeStart = tmpTimeStart;
    reservationData.timeEnd = tmpTimeEnd;
    reservationData.decisionDate = new Date();

    await axios
      .put(
        `${API_ROUTE}reservation`,
        {
          clickedReservData: reservationData,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.status === "failure") {
          toggleTextModal();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    allReasons.forEach((val) => {
      if (val._id === reservationData.reasonId) {
        setReasonName(val.designation);
      }
    });
    setTimeStart(
      `${dateTimeStart.getFullYear()}-${(
        "0" +
        (parseInt(dateTimeStart.getMonth()) + 1)
      ).slice(-2)}-${("0" + dateTimeStart.getDate()).slice(-2)}`
    );
    setHourStart(
      `${
        parseInt(dateTimeStart.getUTCHours()) < 10
          ? "0" + dateTimeStart.getUTCHours()
          : dateTimeStart.getUTCHours()
      }:${(dateTimeStart.getMinutes() + "0").slice(0, 2)}`
    );

    setHourEnd(
      `${
        parseInt(dateTimeEnd.getUTCHours()) < 10
          ? "0" + dateTimeEnd.getUTCHours()
          : dateTimeEnd.getUTCHours()
      }:${(dateTimeEnd.getMinutes() + "0").slice(0, 2)}`
    );
    (async () => {
      if (reservationData.spaceId) {
        await axios
          .get(`${API_ROUTE}space/${reservationData.spaceId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setCurrentSpaceData(res.data.data.spaceData);
          })
          .catch((err) => {});
      }
    })();
  }, [reservationData]);

  useEffect(() => {
    let didCancel = false;

    (async () => {
      await axios
        .get(`${API_ROUTE}reason`, {
          withCredentials: true,
        })
        .then((res) => {
          if (didCancel) return;
          setAllReasons(res.data.data.allReasons);
        })
        .catch((err) => {
          console.log(err.response);
        });
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  const formatTime = (time) => {
    if (parseInt(time) < 10) {
      return "0" + time;
    }
    return time;
  };

  // every time space array is updated, spacesOptions is updated too
  useEffect(() => {
    if (currentSpaceData) {
      let spacesSameType = [];
      let spacesNotSameType = [];
      spaces.forEach((space) => {
        if (space.type === currentSpaceData.type) {
          spacesSameType.push(space);
        } else {
          spacesNotSameType.push(space);
        }
      });
      spacesSameType.sort((a, b) => b.capacity - a.capacity);
      spacesNotSameType.sort((a, b) => b.capacity - a.capacity);

      const sortedSpacesArray = spacesSameType.concat(spacesNotSameType);
      const optionsArr = sortedSpacesArray.map((val) => {
        const formatedStartHour = `${formatTime(
          new Date(reservationData.timeStart).getUTCHours()
        )}:${formatTime(new Date(reservationData.timeStart).getUTCMinutes())}`;
        const formatedEndHour = `${formatTime(
          new Date(reservationData.timeEnd).getUTCHours()
        )}:${formatTime(new Date(reservationData.timeEnd).getUTCMinutes())}`;
        if (
          val.capacity >= currentSpaceData.capacity &&
          val.type === currentSpaceData.type &&
          val.hasPendentRequest &&
          ((hourEnd !== formatedEndHour &&
            val._id !== reservationData.spaceId) ||
            (hourStart !== formatedStartHour &&
              val._id !== reservationData.spaceId) ||
            (val._id === reservationData.spaceId &&
              hourEnd > formatedStartHour &&
              hourEnd < formatedEndHour) ||
            (val._id === reservationData.spaceId &&
              hourStart > formatedStartHour &&
              hourStart < formatedEndHour))
        ) {
          return {
            value: val._id,
            label: val.name,
            capacity: val.capacity,
            isSystemSuggestion: true,
            hasPendentRequest: true,
          };
        } else if (
          val.capacity >= currentSpaceData.capacity &&
          val.type === currentSpaceData.type
        ) {
          return {
            value: val._id,
            label: val.name,
            capacity: val.capacity,
            isSystemSuggestion: true,
            hasPendentRequest: false,
          };
        } else if (
          val.hasPendentRequest &&
          ((hourEnd !== formatedEndHour &&
            val._id !== reservationData.spaceId) ||
            (hourStart !== formatedStartHour &&
              val._id !== reservationData.spaceId) ||
            (val._id === reservationData.spaceId &&
              hourEnd > formatedStartHour &&
              hourEnd < formatedEndHour) ||
            (val._id === reservationData.spaceId &&
              hourStart > formatedStartHour &&
              hourStart < formatedEndHour))
        ) {
          return {
            value: val._id,
            label: val.name,
            capacity: val.capacity,
            isSystemSuggestion: false,
            hasPendentRequest: true,
          };
        }

        return {
          value: val._id,
          label: val.name,
          capacity: val.capacity,
          isSystemSuggestion: false,
          hasPendentRequest: false,
        };
      });

      setSpacesOptions(optionsArr);
    }
  }, [spaces]);

  const findSpaceSelectedInOptionsById = (spaceSelectedId) => {
    return spacesOptions.find((space) => {
      return space.value === spaceId;
    });
  };

  const verifySpaceSelectedConflicts = () => {
    if (
      (spaceId && !findSpaceSelectedInOptionsById(spaceId)) ||
      (spaceId &&
        findSpaceSelectedInOptionsById(spaceId) &&
        findSpaceSelectedInOptionsById(spaceId).hasPendentRequest)
    ) {
      return (
        <>
          <GoAlert /> Existe uma reserva neste horário
        </>
      );
    }
  };

  const validateHourInputs = () => {
    return !isFirstStringTimeGreaterThanSecond(hourStart, hourEnd);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            size="lg"
            show={isShowing}
            onHide={() => {
              setSpaceId(null);
              setIsInputTimeNotValid(false);

              toggle();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Editar Reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Espaço Original
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        aria-label="Default"
                        disabled
                        defaultValue={reservationData.spaceName}
                        aria-describedby="inputGroup-sizing-default"
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Nome
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        aria-label="Default"
                        disabled
                        defaultValue={reservationData.requester}
                        aria-describedby="inputGroup-sizing-default"
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Motivo</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        aria-label="With textarea"
                        defaultValue={reason}
                        disabled
                      ></Form.Control>
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Descrição</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        as="textarea"
                        disabled
                        aria-label="With textarea"
                        defaultValue={reservationData.description}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Novo Espaço</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Select
                        placeholder="Selecione ou digite o nome de algum espaço"
                        components={{ Option }}
                        options={spacesOptions}
                        className="space-select"
                        onChange={handleSelectSpace}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Data da Reserva</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="date"
                        value={timeDate}
                        onChange={(evt) => {
                          setTimeStart(evt.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Hora de Início</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="time"
                        value={hourStart}
                        onChange={(evt) => {
                          setHourStart(evt.target.value);
                        }}
                        step={1800}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Hora de Fim</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="time"
                        value={hourEnd}
                        onChange={(evt) => {
                          setHourEnd(evt.target.value);
                        }}
                        step={1800}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                {verifySpaceSelectedConflicts()}

                {isInputTimeNotValid ? (
                  <p style={{ color: "red" }}>
                    A hora de fim precisa ser maior do que a hora de início
                  </p>
                ) : null}
              </Container>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => {
                  setIsInputTimeNotValid(false);
                  setSpaceId(null);
                  toggle();
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  // verifies if hour end > hour start
                  if (validateHourInputs()) {
                    // requestReservation();
                    editReservation();
                    // setHourValidation(false);
                    // toggle();
                  } else {
                    setIsInputTimeNotValid(true);
                  }
                }}
                disabled={spaceId ? false : true}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
          <TextModal
            isShowing={isShowingTextModal}
            toggle={toggleTextModal}
            titleText={"Aviso"}
            bodyText={"Já existe uma reserva neste horário"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default EditReservationModal;
