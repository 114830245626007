import React, { useState } from "react";
import CreateReservationReasonModal from "../../components/crud-reservation-reason/create-reservation-reason.component";
import { Button } from "react-bootstrap";
import SelectDeleteReservationReasonModal from "../../components/crud-reservation-reason/select-delete-reservation-reason.component";

import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";

import ListFloorAreaSpaceModal from "../../components/list-floor-area-space/list-floor-area-space-modal.component";

import useModal from '../../utils/useModal'

const ManageReservationReason = (props) => {
  const {
    isShowing: isShowingCreateReservationReasonModal,
    toggle: toggleCreateReservationReasonModal,
  } = useModal();
  const {
    isShowing: isShowingSelectDeleteReservationReasonModal,
    toggle: toggleSelectDeleteReservationReasonModal,
  } = useModal();
  const {
    isShowing: isShowingActionFeedbackModal,
    toggle: toggleActionFeedbackModal,
  } = useModal();
  const {
    isShowing: isShowingListReservationReasonModal,
    toggle: toggleListReservationReasonModal,
  } = useModal();

  const [titleText, setTitleText] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [reasonId, setReasonId] = useState(null);
  const [reasonDesignation, setReasonDesignation] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="manageSystemWrapper">
      <div className="buttonPanel">
        <h5>Gerir Motivo de Reservas</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            // it is necessary to set this as a empty string bcos the default value is the default selected value to edit
            setReasonDesignation("");
            setIsEdit(false);
            toggleCreateReservationReasonModal();
          }}
        >
          Criar Motivo de Reserva
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsEdit(false);
            toggleListReservationReasonModal();
          }}
        >
          Listar Motivo de Reservas
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsEdit(true);
            toggleSelectDeleteReservationReasonModal();
          }}
        >
          Editar Motivo de Reserva
        </Button>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsEdit(false);
            toggleSelectDeleteReservationReasonModal();
          }}
        >
          Remover Motivo de Reserva
        </Button>

        <CreateReservationReasonModal
          isShowing={isShowingCreateReservationReasonModal}
          toggle={toggleCreateReservationReasonModal}
          setIsActionCompleted={setIsActionCompleted}
          toggleActionFeedback={toggleActionFeedbackModal}
          setTitleText={setTitleText}
          reasonId={reasonId}
          isEdit={isEdit}
          reasonDesignation={reasonDesignation}
          setReasonDesignation={setReasonDesignation}
        />

        <SelectDeleteReservationReasonModal
          isShowing={isShowingSelectDeleteReservationReasonModal}
          toggle={toggleSelectDeleteReservationReasonModal}
          setTypeId={setReasonId}
          typeId={reasonId}
          isEdit={isEdit}
          toggleEditSpaceTypeModal={toggleCreateReservationReasonModal}
          setTitleText={setTitleText}
          toggleActionFeedback={toggleActionFeedbackModal}
          setIsActionCompleted={setIsActionCompleted}
          setTypeName={setReasonDesignation}
        />

        <ListFloorAreaSpaceModal
          toggle={toggleListReservationReasonModal}
          isShowing={isShowingListReservationReasonModal}
          setDocumentId={setReasonId}
          setIsActionCompleted={setIsActionCompleted}
          setTitleText={setTitleText}
          toggleActionFeedback={toggleActionFeedbackModal}
          toggleEdit={toggleCreateReservationReasonModal}
          listType="reason"
          setIsEdit={setIsEdit}
        />

        <ActionFeedbackModal
          isShowing={isShowingActionFeedbackModal}
          toggle={toggleActionFeedbackModal}
          isActionCompleted={isActionCompleted}
          titleText={titleText}
        />
      </div>
    </div>
  );
};

export default ManageReservationReason;
