import React, { useCallback } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { FaRegTrashAlt, FaPencilAlt } from "react-icons/fa";

import "./table-cell.styles.scss";

const TableCell = ({
  isSelectedProps,
  day,
  hour,
  counter,
  selectedDate,
  requestMode,
  selectRow,
  selectCol,
  selectCell,
  reservationData,
  userData,
  boxHeight,
  boxWidth,
  boxMarginLeft,
  toggleDeclineReservationModal,
  toggleConfirmationModal,
  toggleEditModal,
  setSelectedReservation,
  setReservationStatus,
  isAcceptBlock,
  onClickDisabled,
}) => {
  const firstRowColumnStyle = {
    backgroundColor: "#C3D9FF",
    cursor: requestMode ? "pointer" : null,
  };

  const days = {
    1: "Seg",
    2: "Ter",
    3: "Qua",
    4: "Qui",
    5: "Sex",
    6: "Sab",
    7: "Dom",
  };

  const buildHour = (value) => {
    if (value - Math.trunc(value) === 0) {
      return Math.trunc(value) + ":00";
    } else {
      return Math.trunc(value) + ":" + (value - Math.trunc(value)) * 60;
    }
  };

  const getHoursAndMinutes = (date) => {
    const dateObject = new Date(date);

    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();

    return parseFloat(`${hours}.${minutes}`);
  };

  const checkHalfAnHourReservation = (timeStart, timeEnd) => {
    const utcHourMinuteStart = getHoursAndMinutes(timeStart);
    const utcHourMinuteEnd = getHoursAndMinutes(timeEnd);
    // toFixed(2) to deal with floating point precision
    const formattedTimeDiff = parseFloat(
      (utcHourMinuteEnd - utcHourMinuteStart).toFixed(2)
    );

    // 0.3 is expected when there's half an hour with (eg. 17.3 - 17)
    // 0.7 when it is 17 - 16.3
    return formattedTimeDiff === 0.3 || formattedTimeDiff === 0.7
      ? true
      : false;
  };

  const renderReservationReason = (isHalfAnHourReservation, reservation) => {
    return isHalfAnHourReservation ? (
      <span
        style={{
          marginLeft: "5px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {reservation.reason}
      </span>
    ) : null;
  };

  const buildHeaderCell = () => {
    const unformattedDate = selectedDate[counter];

    const dayName = days[unformattedDate.split("-")[3]];
    const dayNumber = unformattedDate.split("-")[2];
    const monthNumber = unformattedDate.split("-")[1];

    return (
      <div
        key={`${day}-${hour}`}
        id={`${unformattedDate.split("-")[0]}-${monthNumber}-${dayNumber}`}
        className="item"
        style={firstRowColumnStyle}
        onClick={requestMode ? selectCol : null}
      >
        {`${dayName} (${dayNumber}/${monthNumber})`}
      </div>
    );
  };

  const buildHourColumnCell = () => {
    return (
      <div
        key={`${day}-${hour}`}
        id={`${hour}`}
        className="item"
        style={firstRowColumnStyle}
        onClick={requestMode ? selectRow : null}
      >
        {buildHour(hour) + " - " + buildHour(hour + 0.5)}
      </div>
    );
  };

  const buildEmptyCell = (dayId, monthId, yearId) => {
    return (
      <div
        className={`item ${
          requestMode && !onClickDisabled ? "clickable" : ""
        } id${yearId}-${monthId}-${dayId}-${hour * 10} ${
          isSelectedProps ? "cell-active" : ""
        }`}
        key={`${day}-${hour}`}
        id={`id${yearId}-${monthId}-${dayId}-${hour * 10}`}
        onClick={
          requestMode && !onClickDisabled
            ? () => {
                selectCell(yearId, monthId, dayId, hour);
              }
            : null
        }
      ></div>
    );
  };

  const isSpaceManagerOfThisSpace = (reservation) => {
    return reservation.spaces.spaceManagerId.some((spaceManager) => {
      return String(spaceManager) === String(userData._id);
    });
  };

  const buildCell = useCallback(
    (day, hour) => {
      // first cell
      if (day === 0 && hour === 7.5) {
        return <div key={`${day}-${hour}`} className="item"></div>;
      }

      // first row, fill with day names (TABLE HEADER)
      if (hour === 7.5) {
        return buildHeaderCell();
      }

      // first column, fill with the hours
      if (day === 0 && hour !== 7.5) {
        return buildHourColumnCell();
      }

      // if IT IS NOT THE FIRST COLUMN NEITHER THE FIRST ROW
      const dayId = selectedDate[day - 1].split("-")[2];
      const monthId = selectedDate[day - 1].split("-")[1];
      const yearId = selectedDate[day - 1].split("-")[0];

      if (reservationData) {
        const isReserved = reservationData.some(
          (val) => val.status === "reservado"
        );

        const reservationsMarkUp = reservationData.map((reservation, idx) => {
          const isHalfAnHourReservation = checkHalfAnHourReservation(
            reservation.timeStart,
            reservation.timeEnd
          );
          if (reservation.status === "reservado") {
            return (
              <div
                className={`request-box ${
                  boxWidth[idx] < 50 ? "hoverableRequest" : ""
                }`}
                style={{
                  // height: `${2 * boxHeight[idx] + "vw"}`,
                  height: boxHeight[idx] + "px",
                  width: `${boxWidth[idx]}%`,
                  marginLeft: `${boxMarginLeft[idx]}%`,
                  backgroundColor: "#4cb052",
                }}
              >
                <div
                  className="request-box--header"
                  style={{ backgroundColor: "green" }}
                >
                  <span
                    style={{
                      marginLeft: "2%",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {reservation.requester}
                  </span>
                  {renderReservationReason(
                    isHalfAnHourReservation,
                    reservation
                  )}
                  <div className="request-box--icons">
                    {reservation.spaces &&
                    isSpaceManagerOfThisSpace(reservation) ? (
                      <FaPencilAlt
                        className="icon icon-yellow table-cell--icon"
                        onClick={() => {
                          setSelectedReservation(reservation);
                          toggleEditModal();
                        }}
                      ></FaPencilAlt>
                    ) : null}
                    {(reservation.spaces &&
                      isSpaceManagerOfThisSpace(reservation)) ||
                    userData._id === reservation.requesterId ? (
                      <FaRegTrashAlt
                        className="icon-trash table-cell--icon"
                        onClick={() => {
                          setSelectedReservation(reservation);
                          setReservationStatus("cancelado");
                          toggleConfirmationModal();
                        }}
                      ></FaRegTrashAlt>
                    ) : null}
                  </div>
                </div>
                <div
                  className="request-box--content"
                  style={
                    {
                      // height: `${2 * boxHeight[idx] + "vw"}`,
                    }
                  }
                >
                  {renderReservationReason(
                    !isHalfAnHourReservation,
                    reservation
                  )}
                </div>
              </div>
            );
          } else if (reservation.status === "em análise") {
            return (
              <div
                className={`request-box ${
                  boxWidth[idx] < 50 ? "hoverableRequest" : ""
                }`}
                style={{
                  height: boxHeight[idx] + "px",
                  width: `${boxWidth[idx]}%`,
                  marginLeft: `${boxMarginLeft[idx]}%`,
                }}
              >
                <div className="request-box--header">
                  <span
                    style={{
                      marginLeft: "2%",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {reservation.requester}
                  </span>
                  {renderReservationReason(
                    isHalfAnHourReservation,
                    reservation
                  )}
                  <div className="request-box--icons">
                    {reservation.spaces &&
                    isSpaceManagerOfThisSpace(reservation) &&
                    !isAcceptBlock ? (
                      <IoMdCheckmark
                        className="icon-check table-cell--icon"
                        onClick={() => {
                          setSelectedReservation(reservation);
                          setReservationStatus("reservado");
                          toggleConfirmationModal();
                        }}
                      />
                    ) : null}

                    {reservation.spaces &&
                    isSpaceManagerOfThisSpace(reservation) ? (
                      <FaPencilAlt
                        className="icon icon-yellow table-cell--icon"
                        onClick={() => {
                          setSelectedReservation(reservation);
                          toggleEditModal();
                        }}
                      ></FaPencilAlt>
                    ) : null}
                    {(reservation.spaces &&
                      isSpaceManagerOfThisSpace(reservation)) ||
                    userData._id === reservation.requesterId ? (
                      <FaRegTrashAlt
                        className="icon-trash table-cell--icon"
                        onClick={() => {
                          setSelectedReservation(reservation);
                          if (isSpaceManagerOfThisSpace(reservation)) {
                            setReservationStatus("rejeitado");
                            toggleDeclineReservationModal();
                          } else {
                            setReservationStatus("cancelado");
                            toggleConfirmationModal();
                          }
                        }}
                      ></FaRegTrashAlt>
                    ) : null}
                  </div>
                </div>
                <div
                  className="request-box--content"
                  style={
                    {
                      // height: `${2 * boxHeight[idx] + "vw"}`,
                    }
                  }
                >
                  {renderReservationReason(
                    !isHalfAnHourReservation,
                    reservation
                  )}
                </div>
              </div>
            );
          }
        });

        return (
          <div
            key={`${day}-${hour}`}
            id={`id${yearId}-${monthId}-${dayId}-${hour * 10}`}
            className={`item  ${
              isReserved ? null : requestMode ? "clickable" : ""
            } id${yearId}-${monthId}-${dayId}-${hour * 10} ${
              isSelectedProps ? "cell-active" : null
            }`}
            onClick={
              isReserved
                ? null
                : requestMode
                ? () => {
                    selectCell(yearId, monthId, dayId, hour);
                  }
                : null
            }
          >
            {reservationsMarkUp}
          </div>
        );
      }

      return buildEmptyCell(dayId, monthId, yearId);
    },
    [
      isSelectedProps,
      day,
      hour,
      counter,
      selectedDate,
      requestMode,
      selectRow,
      selectCol,
      selectCell,
      reservationData,
      userData,
      boxHeight,
      boxWidth,
      boxMarginLeft,
      toggleDeclineReservationModal,
      toggleConfirmationModal,
      toggleEditModal,
      setSelectedReservation,
      setReservationStatus,
      isAcceptBlock,
    ]
  );

  return buildCell(day, hour);
};

export default TableCell;
