export const isFirstStringTimeGreaterThanSecond = (
  stringTime1,
  stringTime2
) => {
  const hour1 = parseInt(stringTime1.split(":")[0]);
  const minute1 = parseInt(stringTime1.split(":")[1]);

  let hour2 = parseInt(stringTime2.split(":")[0]);
  const minute2 = parseInt(stringTime2.split(":")[1]);

  if (hour2 === 0) hour2 = 24; // midnight is treated as 24 in this case

  if (hour1 > hour2) return true;
  else if (hour1 === hour2 && minute1 >= minute2) return true;
  return false;
};
