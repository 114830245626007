import React, { useState } from "react";
import CreateSpaceTypeModal from "./create-space-type.component";
import { Button } from "react-bootstrap";
import SelectDeleteSpaceTypeModal from "./select-delete-space-type.component";
import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";

import ListFloorAreaSpaceModal from "../list-floor-area-space/list-floor-area-space-modal.component";

import useModal from '../../utils/useModal'

const CrudSpaceType = (props) => {
  const { isShowing: isShowingCreateSpaceTypeModal, toggle: toggleCreateSpaceTypeModal } = useModal();
  const { isShowing: isShowingSelectDeleteSpaceTypeModal, toggle: toggleSelectDeleteSpaceTypeModal } = useModal();
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();
  const { isShowing: isShowingListFloorAreaSpaceModal, toggle: toggleListFloorAreaSpaceModal } = useModal();

  const [titleText, setTitleText] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [typeId, setTypeId] = useState(null);
  const [typeName, setTypeName] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <h5>Gerir Tipos de Espaços</h5>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          // it is necessary to set this as a empty string bcos the default value is the default selected value to edit
          setTypeName("");
          setIsEdit(false);
          toggleCreateSpaceTypeModal();
        }}
      >
        Criar Tipo de Espaço
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(false);
          toggleListFloorAreaSpaceModal();
        }}
      >
        Listar Tipos de Espaços
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(true);
          toggleSelectDeleteSpaceTypeModal();
        }}
      >
        Editar Tipo de Espaço
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(false);
          toggleSelectDeleteSpaceTypeModal();
        }}
      >
        Remover Tipo de Espaço
      </Button>

      <CreateSpaceTypeModal
        isShowing={isShowingCreateSpaceTypeModal}
        toggle={toggleCreateSpaceTypeModal}
        setIsActionCompleted={setIsActionCompleted}
        toggleActionFeedback={toggleActionFeedbackModal}
        setTitleText={setTitleText}
        typeId={typeId}
        isEdit={isEdit}
        typeName={typeName}
        setTypeName={setTypeName}
      />

      <SelectDeleteSpaceTypeModal
        isShowing={isShowingSelectDeleteSpaceTypeModal}
        toggle={toggleSelectDeleteSpaceTypeModal}
        setTypeId={setTypeId}
        typeId={typeId}
        isEdit={isEdit}
        toggleEditSpaceTypeModal={toggleCreateSpaceTypeModal}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
        setTypeName={setTypeName}
      />

      <ListFloorAreaSpaceModal
        toggle={toggleListFloorAreaSpaceModal}
        isShowing={isShowingListFloorAreaSpaceModal}
        setDocumentId={setTypeId}
        setIsActionCompleted={setIsActionCompleted}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        toggleEdit={toggleCreateSpaceTypeModal}
        listType="spaceType"
        setIsEdit={setIsEdit}
      />

      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={titleText}
      />
    </>
  );
};

export default CrudSpaceType;
