import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";
import ListFloorAreaSpaceModal from "../list-floor-area-space/list-floor-area-space-modal.component";

import CreateEditResource from "./create-edit-resource.component";
import SelectDeleteResource from "./select-delete-resource.component";
import useModal from '../../utils/useModal'

const CrudResource = (props) => {
  const { isShowing: isShowingCreateEditResource, toggle: toggleCreateEditResource } = useModal();
  const { isShowing: isShowingSelectDeleteResource, toggle: toggleSelectDeleteResource } = useModal();
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();
  const { isShowing: isShowingListFloorAreaSpaceModal, toggle: toggleListFloorAreaSpaceModal } = useModal();

  const [titleText, setTitleText] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [resourceName, setResourceName] = useState("");
  const [description, setDescription] = useState("");

  const [resourceId, setResourceId] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <h5>Gerir Recursos</h5>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setResourceName("");
          setDescription("");
          setIsEdit(false);
          toggleCreateEditResource();
        }}
      >
        Criar Recurso
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(false);
          toggleListFloorAreaSpaceModal();
        }}
      >
        Listar Recursos
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(true);
          toggleSelectDeleteResource();
        }}
      >
        Editar Recurso
      </Button>
      <Button
        variant="outline-info"
        block
        onClick={() => {
          setIsEdit(false);
          toggleSelectDeleteResource();
        }}
      >
        Remover Recurso
      </Button>

      <CreateEditResource
        isShowing={isShowingCreateEditResource}
        toggle={toggleCreateEditResource}
        setTitleText={setTitleText}
        resourceId={resourceId}
        isEdit={isEdit}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
        resourceName={resourceName}
        description={description}
        setResourceName={setResourceName}
        setDescription={setDescription}
      />

      <SelectDeleteResource
        toggle={toggleSelectDeleteResource}
        isShowing={isShowingSelectDeleteResource}
        setResourceId={setResourceId}
        resourceId={resourceId}
        toggleEditResourceModal={toggleCreateEditResource}
        isEdit={isEdit}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
        setResourceName={setResourceName}
        setDescription={setDescription}
      />

      <ListFloorAreaSpaceModal
        toggle={toggleListFloorAreaSpaceModal}
        isShowing={isShowingListFloorAreaSpaceModal}
        setDocumentId={setResourceId}
        setIsActionCompleted={setIsActionCompleted}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        toggleEdit={toggleCreateEditResource}
        listType="resource"
        setIsEdit={setIsEdit}
      />

      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={titleText}
      />
    </>
  );
};

export default CrudResource;
