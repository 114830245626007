import React, { useState } from "react";

import useModal from '../../utils/useModal'
import SelectEditFloor from "./select-edit-floor.component";
import EditFloorModal from "./edit-floor-modal.component";
import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";

import ListFloorAreaSpaceModal from "../list-floor-area-space/list-floor-area-space-modal.component";

const EditFloor = ({
  isShowing,
  toggle,
  isEdit,
  isShowingListFloor,
  toggleListFloor,
  setIsEdit,
}) => {
  const { isShowing: isShowingEditFloorModal, toggle: toggleEditFloorModal } = useModal();
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();

  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [floorId, setFloorId] = useState(null);
  const [titleText, setTitleText] = useState(null);

  return (
    <>
      <SelectEditFloor
        isShowing={isShowing}
        setFloorId={setFloorId}
        floorId={floorId}
        toggle={toggle}
        toggleEditFloorModal={toggleEditFloorModal}
        isEdit={isEdit}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
      />

      <ListFloorAreaSpaceModal
        setIsEdit={setIsEdit}
        toggle={toggleListFloor}
        isShowing={isShowingListFloor}
        setDocumentId={setFloorId}
        setIsActionCompleted={setIsActionCompleted}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        toggleEdit={toggleEditFloorModal}
        listType="floor"
      />

      <EditFloorModal
        isShowing={isShowingEditFloorModal}
        toggle={toggleEditFloorModal}
        floorId={floorId}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
      />
      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={titleText}
      />
    </>
  );
};

export default EditFloor;
