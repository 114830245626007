import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import useModal from "../../utils/useModal";

const SelectDeleteReservationReasonModal = ({
  isShowing,
  toggle,
  setTypeId: setReasonId,
  typeId: reasonId,
  toggleEditSpaceTypeModal: toggleEditReservationReasonModal,
  isEdit,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
  setTypeName: setReasonDesignation,
}) => {
  const [allReasons, setAllReasons] = useState([]);
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [selectedReservationReason, setSelectedReservationReason] = useState(
    null
  );

  const handleChange = (selected) => {
    setSelectedReservationReason(selected);
    setReasonId(selected.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      toggleEditReservationReasonModal();
      (async () => {
        await axios
          .get(`${API_ROUTE}reason/${reasonId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setReasonDesignation(res.data.data.reason.designation);
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
          });
      })();
      toggle();
    } else {
      toggleConfirmationModal();
    }
  };

  const deleteReservationReason = () => {
    (async () => {
      if (reasonId !== null) {
        await axios
          .delete(`${API_ROUTE}reason/${reasonId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const reservationsUsingThisReason = res.data.data.reasons;
            if (reservationsUsingThisReason.length > 0) {
              const reservationsIds = reservationsUsingThisReason.map(
                (reservation) => reservation._id
              );
              setTitleText(
                `A(s) seguinte(s) reservas(s) utilizam este motivo: ${reservationsIds}, remova-a(s) antes de remover este motivo!`
              );
              setIsActionCompleted(false);
            } else {
              setTitleText("Motivo de reserva removido com sucesso!");
              setIsActionCompleted(true);
            }
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
          })
          .finally(() => {
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          });
      }
    })();
  };

  // get all types to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}reason`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllReasons = res.data.data.allReasons.map((reason) => {
            return {
              value: reason._id,
              label: reason.designation,
            };
          });
          if (tmpAllReasons.length > 0) {
            setSelectedReservationReason(tmpAllReasons[0]);
            setReasonDesignation(tmpAllReasons[0].label);
            setReasonId(tmpAllReasons[0].value); // default initial value
            setAllReasons(tmpAllReasons);
          }
        });
    })();
  }, []);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit
                    ? "Editar Motivo de Reserva"
                    : "Remover Motivo de Reserva"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    {isEdit
                      ? "Selecione um motivo de reserva para editar"
                      : "Selecione um motivo de reserva para remover"}
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o motivo de reserva"
                    options={allReasons}
                    className="react-select"
                    value={selectedReservationReason}
                    onChange={handleChange}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  {isEdit
                    ? "Selecionar Tipo de Espaço"
                    : "Remover Tipo de Espaço"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteReservationReason}
            titleText={"Remover Tipo de Espaço"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectDeleteReservationReasonModal;
