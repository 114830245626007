import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";

import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";

import API_ROUTE from "../../utils/apiRoute";

const CreateEditResource = ({
  isShowing,
  toggle,
  setTitleText,
  resourceId,
  isEdit,
  toggleActionFeedback,
  setIsActionCompleted,
  resourceName,
  setResourceName,
  description,
  setDescription,
}) => {
  const [validated, setValidated] = useState(false);

  const handleResourceNameChange = (evt) => {
    setResourceName(evt.currentTarget.value);
  };

  const handleDescriptionChange = (evt) => {
    setDescription(evt.currentTarget.value);
  };

  useEffect(() => {
    if (resourceId) {
      if (isEdit) {
        (async () => {
          await axios
            .get(`${API_ROUTE}resource/${resourceId}`, {
              withCredentials: true,
            })
            .then((res) => {
              setResourceName(res.data.data.resource.name);
              setDescription(res.data.data.resource.description);
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
            });
        })();
      }
    }
  }, [resourceId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (isEdit) {
        const dataToSend = {
          newResourceName: resourceName,
          newResourceDescription: description,
        };
        (async () => {
          await axios
            .patch(`${API_ROUTE}resource/${resourceId}`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Recurso alterado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      } else {
        const dataToSend = {
          name: resourceName,
          description: description,
        };
        (async () => {
          await axios
            .post(`${API_ROUTE}resource`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Recurso criado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      }
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit ? "Editar Recurso" : "Criar Recurso"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome do Recurso"
                    value={resourceName}
                    onChange={handleResourceNameChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    O recurso precisa de um nome!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Descrição do Recurso"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    O recurso precisa de uma descrição!
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CreateEditResource;
