import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import useModal from "../../utils/useModal";
import DeleteReservationModal from "../../components/delete-reservation-modal/delete-reservation-modal.component";
import ListSpacesModal from "../../components/list-spaces-modal/list-spaces-modal.component";

import API_ROUTE from "../../utils/apiRoute";

const ConsultSpaceManager = ({ isAdmin }) => {
  const [allSpaces, setAllSpaces] = useState([]);
  const {
    isShowing: isShowingListSpacesModal,
    toggle: toggleListSpacesModal,
  } = useModal();

  const getSpacesThatImManager = () => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee/myspaces`, {
          withCredentials: true,
        })
        .then((res) => {
          setAllSpaces(res.data.data.spaces);
        })
        .catch((err) => {
          alert("Error while trying to get spaces.. please contact someone");
        });
    })();
  };

  useEffect(() => {
    if (isAdmin) {
      // request for admins
    } else {
      getSpacesThatImManager();
    }
  }, []);

  return (
    <div>
      <Button
        variant="outline-info"
        style={{ marginTop: "10%" }}
        onClick={toggleListSpacesModal}
      >
        {isAdmin
          ? "Buscar Espaços de um Gestor"
          : "Listar Espaços Onde Sou Gestor"}
      </Button>
      <ListSpacesModal
        allSpaces={allSpaces}
        isShowing={isShowingListSpacesModal}
        toggle={toggleListSpacesModal}
      />
    </div>
  );
};

export default ConsultSpaceManager;
