const colors = {
  divider: "#34eb49",
  error: "#ff3447",
};

export const customInvalidStyle = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#2684FF",
    },
  }),
  control: (base, state) => {
    let statusColor = colors.divider;

    if (state.selectProps.error) {
      // "state.selectProps" references the component props
      statusColor = colors.error;
    }

    return {
      ...base,
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
      borderColor: state.isFocused ? "#dc3545" : "#dc3545",
      "&:hover": {
        borderColor: state.isFocused ? "#dc3545" : "#dc3545",
      },
    };
  },
};

export const customValidatedStyle = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#2684FF",
    },
  }),
  control: (base, state) => {
    let statusColor = colors.divider;

    if (state.selectProps.error) {
      // "state.selectProps" references the component props
      statusColor = colors.error;
    }

    return {
      ...base,
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
      borderColor: state.isFocused ? "#28a745" : "#28a745",
      "&:hover": {
        borderColor: state.isFocused ? "#28a745" : "#28a745",
      },
    };
  },
};

export const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#2684FF",
    },
  }),
  control: (base, state) => {
    let statusColor = colors.divider;

    if (state.selectProps.error) {
      // "state.selectProps" references the component props
      statusColor = colors.error;
    }

    return {
      ...base,
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
      borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
      },
    };
  },
};
