const INITAL_STATE = {
  employeeRole: null,
  employeeName: "",
};

const employeeReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case "SET_EMPLOYEE_ROLE":
      return {
        ...state,
        employeeRole: action.payload,
      };

    case "SET_EMPLOYEE_NAME":
      return {
        ...state,
        employeeName: action.payload,
      };

    default:
      return state;
  }
};

export default employeeReducer;
