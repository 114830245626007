import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useModal from "../../utils/useModal";
import ManagePermissionModal from "../../components/manage-permission-modal/manage-permission-modal.component";
import ManageSpaceManagersModal from "../../components/manage-space-managers/manage-space-managers.modal.component";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";

const ManagePermissions = (props) => {
  const {
    isShowing: isShowingManagePermissionModal,
    toggle: toggleManagePermissionModal,
  } = useModal();

  const {
    isShowing: isShowingActionFeedbackModal,
    toggle: toggleActionFeedbackModal,
  } = useModal();

  const [
    actionFeedbackModalTitleText,
    setActionFeedbackModalTitleText,
  ] = useState("");

  const {
    isShowing: isShowingManageSpaceManagersModal,
    toggle: toggleManageSpaceManagersModal,
  } = useModal();

  const [isAddSpaceManager, setIsAddSpaceManager] = useState(true);

  const [isActionCompleted, setIsActionCompleted] = useState(false);

  return (
    <div className="manageSystemWrapper">
      <div className="buttonPanel">
        <h5>Gerir Permissões de Utilizador</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            toggleManagePermissionModal();
          }}
        >
          Gerir Permissão
        </Button>
      </div>

      <div className="buttonPanel">
        <h5>Gerir Gestores de Espaço</h5>
        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsAddSpaceManager(true);
            toggleManageSpaceManagersModal();
          }}
        >
          Adicionar gestor de espaço
        </Button>

        <Button
          variant="outline-info"
          block
          onClick={() => {
            setIsAddSpaceManager(false);
            toggleManageSpaceManagersModal();
          }}
        >
          Remover gestor de espaço
        </Button>
      </div>

      <ManagePermissionModal
        isShowing={isShowingManagePermissionModal}
        toggle={toggleManagePermissionModal}
        setActionFeedbackModalTitleText={setActionFeedbackModalTitleText}
        setActionFeedbackModalAction={setIsActionCompleted}
        toggleActionFeedbackModal={toggleActionFeedbackModal}
      />

      <ManageSpaceManagersModal
        isShowing={isShowingManageSpaceManagersModal}
        toggle={toggleManageSpaceManagersModal}
        isAddSpaceManager={isAddSpaceManager}
        setActionFeedbackModalAction={setIsActionCompleted}
        setActionFeedbackModalTitleText={setActionFeedbackModalTitleText}
        toggleActionFeedbackModal={toggleActionFeedbackModal}
      />

      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={actionFeedbackModalTitleText}
      />
    </div>
  );
};

export default ManagePermissions;
