import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

import "./homepage.styles.scss";

import API_ROUTE from "../../utils/apiRoute";

const HomePage = () => {
  const [allFloors, setAllFloors] = useState([]);

  useEffect(() => {
    let didCancel = false;
    (async () => {
      await axios
        .get(`${API_ROUTE}floor`, {
          withCredentials: true,
        })
        .then((res) => {
          if(didCancel) return;
          setAllFloors(res.data.data.allFloors);
        })
        .catch((err) => {
          console.log(err.response);
        });
    })();

    return () => { didCancel = true; }
  }, []);

  const createButtons = () => {
    return React.Children.toArray(
      allFloors.map((floor) => {
        return (
          <Button
            variant="outline-info"
            size="lg"
            block
            href={`/floor/${floor._id}/`}
          >
            {floor.name}
          </Button>
        );
      })
    );
  };

  return (
    <div className="homepage__layout">
      <div className="homepage__text">
        <p>
          Selecione um piso para começar a navegar entre as áreas e espaços.
        </p>
      </div>
      <div className="homepage__btnColumn">{createButtons()}</div>
    </div>
  );
};

export default HomePage;
