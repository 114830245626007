import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";
const CreateSpaceTypeModal = ({
  isShowing,
  toggle,
  setTitleText,
  typeId,
  isEdit,
  toggleActionFeedback,
  setIsActionCompleted,
  typeName,
  setTypeName,
}) => {
  const [validated, setValidated] = useState(false);

  const handleTypeNameChange = (evt) => {
    setTypeName(evt.currentTarget.value);
  };

  // default value if it is edit
  useEffect(() => {
    if (typeId) {
      if (isEdit) {
        (async () => {
          await axios
            .get(`${API_ROUTE}type/${typeId}`, {
              withCredentials: true,
            })
            .then((res) => {
              setTypeName(res.data.data.type.name);
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
            });
        })();
      }
    }
  }, [typeId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (isEdit) {
        const dataToSend = {
          newTypeName: typeName,
        };
        (async () => {
          await axios
            .patch(`${API_ROUTE}type/${typeId}`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Tipo de espaço alterado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      } else {
        const dataToSend = {
          name: typeName,
        };
        (async () => {
          await axios
            .post(`${API_ROUTE}type`, dataToSend, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Tipo de espaço criado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedback();
              toggle();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedback();
              toggle();
            });
        })();
      }
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit ? "Editar Tipo de Espaço" : "Criar Tipo De Espaço"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome do tipo de espaço"
                    value={typeName}
                    onChange={handleTypeNameChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    O tipo de espaço precisa de um nome!
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CreateSpaceTypeModal;
