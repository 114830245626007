import React from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
  isShowing,
  toggle,
  handleConfirmation,
  titleText,
}) => {
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal show={isShowing} onHide={toggle} size="sm">
            <Modal.Header closeButton>
              <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza que deseja continuar?</Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  handleConfirmation();
                }}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ConfirmationModal;
