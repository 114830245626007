import React from "react";
import CustomForm from "../../components/custom-form/custom-form.component";
import WithSpinner from "../../components/spinner/with-spinner.component";
import "./space-info.styles.scss";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SideInfo from "../../components/side-info/side-info.component";

const CustomFormWithSpinner = WithSpinner(CustomForm);

class SpaceInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      spaceName: "",
      spaceData: {},
      isLoading: true,
    };
  }

  getData = async () => {
    await axios
      .get(`${API_ROUTE}space/${this.props.match.params.spaceinfoId}`, {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({
          spaceData: res.data.data.spaceData,
        });
        this.setState({
          spaceName: res.data.data.spaceData.name,
        });

        this.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div className="spaceInfo_layout">
        <div className="spaceInfo_layout-form">
          <h2>{this.state.spaceName}</h2>

          <CustomFormWithSpinner
            isLoading={this.state.isLoading}
            {...this.state.spaceData}
          />
        </div>
        <div className="spaceInfo_layout-calendar">
          {this.props.employeeRole === "system-manager" ? null : (
            <h2>Reservar Espaço</h2>
          )}

          <SideInfo
            history={this.props.history}
            spaceId={this.props.match.params.spaceinfoId}
            isSystemManager={
              this.props.employeeRole === "system-manager" ? true : false
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeRole: state.employee.employeeRole,
});

export default connect(mapStateToProps)(withRouter(SpaceInfo));
