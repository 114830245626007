import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";

const EditAreaModal = ({
  isShowing,
  toggle,
  areaId,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
}) => {
  const [validated, setValidated] = useState(false);

  const [areaNameDefault, setAreaNameDefault] = useState("");
  const nameRef = useState(React.createRef())[0];

  useEffect(() => {
    (async () => {
      if (areaId !== null) {
        await axios
          .get(`${API_ROUTE}area/${areaId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const areaName = res.data.data.areaData.name;
            setAreaNameDefault(areaName);
          });
        // .catch((err) => alert(err));
      }
    })();
  }, [areaId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const dataToSend = {
        newAreaName: nameRef.current.value,
      };
      (async () => {
        await axios
          .patch(`${API_ROUTE}area/${areaId}`, dataToSend, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Área alterada com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggle();
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggle();
          });
      })();
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleChange = (evt) => {
    setAreaNameDefault(evt.currentTarget.value);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar Área</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome da Área"
                    value={areaNameDefault}
                    onChange={handleChange}
                    ref={nameRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    A área precisa de um nome!
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default EditAreaModal;
