import React from "react";
import { Button } from "react-bootstrap";
const AreaInfo = ({ areaData, areaId }) => {
  return (
    <div>
      <h5>{areaData.name}</h5>
      <p>Quantidade de Espaços: {areaData.spaces.length}</p>
      <a href={`area/${areaId}/`}>
        <Button className="floor__button--select-area" size="sm">
          Ir para Área
        </Button>
      </a>
    </div>
  );
};

export default AreaInfo;
