import React, { useState } from "react";
import { Button } from "react-bootstrap";
import useModal from '../../utils/useModal'

import EditSpace from "../../components/edit-space/edit-space.component";
import CrudResource from "../../components/crud-resource/crud-resource.component";

const EditSpacePage = () => {
  const { isShowing: isShowingEditSpace, toggle: toggleEditSpace } = useModal();

  const [isEdit, setIsEdit] = useState(true);

  return (
    <div
      className=""
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        marginTop: "1rem",
      }}
    >
      <div className="" style={{ justifySelf: "center" }}>
        <h5>Alterar Informações dos Espaços</h5>

        <Button
          variant="outline-info"
          block
          onClick={() => {
            toggleEditSpace();
            setIsEdit(true);
          }}
        >
          Editar Espaço
        </Button>
      </div>

      <div className="" style={{ justifySelf: "center" }}>
        <CrudResource />
      </div>

      <EditSpace
        toggle={toggleEditSpace}
        isShowing={isShowingEditSpace}
        isEdit={isEdit}
        spaceSelectType="edit"
      />
    </div>
  );
};

export default EditSpacePage;
