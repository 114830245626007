import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

import BreadCrumb from "../../components/breadcrumb/breadcrumb.component";
import SpecificArea from "../../components/specific-area/specific-area.component";
import WithSpinner from "../../components/spinner/with-spinner.component";
import AreaInfo from "../../components/area-info/area-info.component";

import axios from "axios";
import "./floor.styles.scss";

import API_ROUTE from "../../utils/apiRoute";

const SpecificAreaWithSpinner = WithSpinner(SpecificArea);

class Floor extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      id: null,
      breadCrumbArray: [],
      floorName: "",
      floorObjectData: {},
      areaData: [],
      coordsAndId: [],
      allFloorsName: [],
      allFloorsId: [],
      isLoading: true,
      showAllSelectableArea: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setShowAllSelectableArea = () => {
    this.setState((prevState) => ({
      showAllSelectableArea: !prevState.showAllSelectableArea,
    }));
  };

  getData = async () => {
    await axios
      .get(`${API_ROUTE}floor/${this.props.match.params.floorId}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (!this._isMounted) return;
        this.setState({ floorName: res.data.data.floorData.name });
        this.setState({ floorObjectData: res.data.data.floorData });
        this.setState({ areaData: res.data.data.areaData }, () => {
          this.setState({
            coordsAndId: this.state.areaData.map((val) => {
              return { coords: val.coordinates, id: val._id };
            }),
          });
        });
      });

    await axios
      .get(`${API_ROUTE}floor`, {
        withCredentials: true,
      })
      .then((res) => {
        if (!this._isMounted) return;
        let tmpFloorsNames = [];
        let tmpFloorsIds = [];
        res.data.data.allFloors.forEach((element) => {
          tmpFloorsNames.push(element.name);
          tmpFloorsIds.push("/floor/" + element._id + "/");
        });

        this.setState({ allFloorsName: tmpFloorsNames });
        this.setState({ allFloorsId: tmpFloorsIds });
      });
  };

  createBreadCrumb = () => {
    const tmpBreadCrumb = [
      {
        name: "Piso",
        isToggle: true,
        isActive: false,
        items: this.state.allFloorsName,
        links: this.state.allFloorsId,
      },
      {
        name: this.state.floorName,
        isActive: true,
        isToggle: false,
      },
    ];
    this.setState({ breadCrumbArray: tmpBreadCrumb });
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
    if (!this._isMounted) return;
    this.setState({ isLoading: false });
    this.createBreadCrumb();
  }

  setId = (id) => {
    if (!id) return;

    this.setState({ id });
  };

  removeId = () => {
    this.setState({ id: null });
  };

  goToSelected = () => {
    // put the code on callback queue so that the setState can finish before
    setTimeout(() => {
      this.props.history.push(`area/${this.state.id}/`);
    }, 0);
  };

  goToSelectedArea = () => {
    if (this.state.id) {
      // put the code on callback queue so that the setState can finish before
      setTimeout(() => {
        this.props.history.push(`area/${this.state.id}/`);
      }, 0);
    }
  };

  render() {
    const propsObject = {
      ...this.state.floorObjectData,
      coordinates: this.state.coordsAndId,
      setIdFunc: this.setId,
      removeIdFunc: this.removeId,
      goToSelected: this.goToSelected,
      isFloor: true,
      id: this.state.id,
      showAllSelectableArea: this.state.showAllSelectableArea,
    };

    return (
      <div className="floor-wrapper">
        <div className="floor-headerWrapper">
          <h3>{this.state.floorName}</h3>

          <BreadCrumb
            items={this.state.breadCrumbArray}
            history={this.props.history}
          />

          <Button
            onClick={this.setShowAllSelectableArea}
            className="floor__button--show-area"
            size="sm"
            variant="info"
          >
            Mostrar todas as áreas selecionáveis
          </Button>
        </div>
        <div className="floor__image">
          <SpecificAreaWithSpinner
            isLoading={this.state.isLoading}
            {...propsObject}
            isFloor={true}
          />
          <div className="floor__area-info">
            {this.state.id !== null ? (
              <AreaInfo
                areaData={this.state.areaData.find((val) => {
                  return val._id === this.state.id;
                })}
                areaId={this.state.id}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Floor);
