import React from "react";
import image from "../../img/404.png";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#ffe4ea",
        height: "100vh",
        width: "100vw",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <img
          src={image}
          alt="Dog"
          style={{ width: "25%", alignSelf: "center", marginTop: "2rem" }}
        />
        <h5 style={{ margin: 0, display: "inline-block", alignSelf: "center" }}>
          A dog ate this page
        </h5>
      </div>
    </div>
  );
};

export default NotFound;
