import React from "react";
import { Button } from "react-bootstrap";

import useModal from "../../utils/useModal";
import DownloadExcelModal from "../../components/download-excel-modal/download-excel-modal.component";

const Reports = (props) => {
  const {
    isShowing: isShowingDownloadExcelModal,
    toggle: toggleShowingDownloadExcelModal,
  } = useModal();

  return (
    <div>
      <Button
        style={{ marginTop: "10%" }}
        variant="outline-info"
        onClick={toggleShowingDownloadExcelModal}
      >
        Download Excel
      </Button>
      <DownloadExcelModal
        toggle={toggleShowingDownloadExcelModal}
        isShowing={isShowingDownloadExcelModal}
      />
    </div>
  );
};

export default Reports;
