import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { Modal, Button, Row, Col, InputGroup } from "react-bootstrap";
import API_ROUTE from "../../utils/apiRoute";

const DownloadExcelModal = ({ isShowing, toggle }) => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true);

  useEffect(() => {
    if (dateStart && dateEnd) {
      if (new Date(dateStart).getTime() <= new Date(dateEnd).getTime()) {
        setIsConfirmBtnDisabled(false);
      } else {
        setIsConfirmBtnDisabled(true);
      }
    } else {
      setIsConfirmBtnDisabled(true);
    }
  }, [dateStart, dateEnd]);

  const downloadSpreadsheet = () => {
    console.log("Date start ", dateStart);
    console.log("Date end ", dateEnd);
    (async () => {
      await axios
        .get(`${API_ROUTE}reservation/spreadsheet`, {
          withCredentials: true,
          responseType: "blob",
          params: {
            dateStart,
            dateEnd,
          },
        })
        .then((res) => {
          console.log("success", res.data);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err.response);
        });
    })();
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Gerar Excel das Reservas Aprovadas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ fontWeight: "500" }}>
                  Selecione um intervalo de tempo para gerar um excel com todas
                  as reservas aprovadas durante o intervalo selecionado.
                </p>
                <Row>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Data de Início</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="date"
                        value={dateStart}
                        onChange={(evt) => {
                          setDateStart(evt.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Data de Fim</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="date"
                        value={dateEnd}
                        onChange={(evt) => {
                          setDateEnd(evt.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  downloadSpreadsheet();
                  toggle();
                }}
                disabled={isConfirmBtnDisabled}
              >
                Download Excel
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>,

        document.body
      )
    : null;
};

export default DownloadExcelModal;
