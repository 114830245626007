import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import useModal from "../../utils/useModal";
const SelectDeleteResource = ({
  isShowing,
  toggle,
  setResourceId,
  resourceId,
  toggleEditResourceModal,
  isEdit,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
  setResourceName,
  setDescription,
}) => {
  const [allResources, setAllResources] = useState([]);
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [selectedResource, setSelectedResource] = useState(null);

  const handleChange = (selected) => {
    setSelectedResource(selected);
    setResourceId(selected.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      toggleEditResourceModal();
      (async () => {
        await axios
          .get(`${API_ROUTE}resource/${resourceId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setResourceName(res.data.data.resource.name);
            setDescription(res.data.data.resource.description);
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
          });
      })();
      toggle();
    } else {
      toggleConfirmationModal();
    }
  };

  const deleteResource = () => {
    (async () => {
      if (resourceId !== null) {
        await axios
          .delete(`${API_ROUTE}resource/${resourceId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Recurso removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  // get all resource to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}resource`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllResources = res.data.data.allResources.map((resource) => {
            return {
              value: resource._id,
              label: resource.name,
              description: resource.description,
            };
          });
          if (tmpAllResources.length > 0) {
            setSelectedResource(tmpAllResources[0]);
            setResourceId(tmpAllResources[0].value); // default initial value
            setResourceName(tmpAllResources[0].label);
            setDescription(tmpAllResources[0].description);
            setAllResources(tmpAllResources);
          }
        });
    })();
  }, []);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit ? "Editar Recurso" : "Remover Recurso"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    {isEdit
                      ? "Selecione um recurso para editar"
                      : "Selecione um recurso para remover"}
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um recurso"
                    options={allResources}
                    className="react-select"
                    value={selectedResource}
                    onChange={handleChange}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  {isEdit ? "Selecionar Recurso" : "Remover Recurso"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteResource}
            titleText={"Remover Recurso"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectDeleteResource;
