import React from "react";
import ReactDOM from "react-dom";
import { Modal, InputGroup, Form, Button } from "react-bootstrap";

const RefuseReservationModal = ({
  isShowing,
  toggle,
  declineRequest,
  inputRef,
}) => {
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal show={isShowing} onHide={toggle}>
            <Modal.Header closeButton>
              <Modal.Title>Recusar Pedido de Reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Justificativa</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  ref={inputRef}
                  as="textarea"
                  defaultValue="Sala indisponível para o período solicitado."
                  aria-label="With textarea"
                />
              </InputGroup>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  declineRequest();
                  toggle();
                }}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default RefuseReservationModal;
