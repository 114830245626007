import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DayPicker, { DateUtils } from "react-day-picker";
import { Button } from "react-bootstrap";
import "react-day-picker/lib/style.css";

import "./side-info.styles.scss";

import { useSelector, useDispatch } from "react-redux";

const SideInfo = ({
  name,
  history,
  type,
  spaceId,
  capacity,
  isSystemManager,
  location,
}) => {
  const selectedDate = useSelector((state) => state.space.selectedDate);
  const dispatch = useDispatch();

  const [daySelected, setDaySelected] = useState([]);
  const [unformattedDateSelected, setUnformattedDateSelected] = useState([]);

  // reset selectedDate state
  useEffect(() => {
    dispatch({ type: "SET_DATE", payload: [] });
  }, []);

  const handleDayClick = (day, { selected }) => {
    if (daySelected.length <= 14 || selected) {
      setDaySelected((prevState) => {
        let selectedDays = [...prevState];
        // const selectedDays = daySelected;
        // if already selected then delete from array
        if (selected) {
          const selectedIndex = selectedDays.findIndex((selectedDay) =>
            DateUtils.isSameDay(selectedDay, day)
          );
          selectedDays.splice(selectedIndex, 1);
        } else {
          selectedDays.push(day);
        }

        // sort the array so that timetable component displays the dates sorted
        selectedDays.sort((a, b) => new Date(a) - new Date(b));

        const formattedDates = selectedDays.map((val) => {
          let day = val.getDay().toString();
          if (val.getDay().toString() === "0") {
            day = "7";
          }
          return `${val.getFullYear().toString()}-${(
            val.getMonth() + 1
          ).toString()}-${val.getDate().toString()}-${day}`;
        });

        dispatch({ type: "SET_DATE", payload: formattedDates });

        setUnformattedDateSelected([...selectedDays]);
        return [...selectedDays];
      });
    } else {
      alert("Selecione no Máximo 15 dias.");
    }
  };

  const changePage = () => {
    if (selectedDate.length <= 0) {
      alert("Selecione pelo menos um dia!");
    } else {
      if (location.pathname.includes("spaceinfo")) {
        const urlArray = location.pathname.split("/");

        history.push(
          `${urlArray[0]}/${urlArray[1]}/${urlArray[2]}/${urlArray[3]}/${urlArray[4]}/timetable/${spaceId}?dates=${unformattedDateSelected}`
        );
        return;
      }
      history.push(`timetable/${spaceId}?dates=${unformattedDateSelected}`);
    }
  };

  return (
    <div className="calendarWrapper">
      {location.pathname.includes("spaceinfo") ? null : (
        <>
          <h3>{name}</h3>
          <p>Tipo: {type}</p>
          <p>Capacidade: {capacity}</p>
        </>
      )}

      {!isSystemManager ? (
        <>
          <DayPicker selectedDays={daySelected} onDayClick={handleDayClick} />
          <Button onClick={changePage}>Consultar Horários</Button>
        </>
      ) : null}
    </div>
  );
};

export default withRouter(SideInfo);
