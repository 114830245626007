import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { withRouter } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import NotFound from "./pages/not-found/not-found.component";
import HomePage from "./pages/homepage/homepage.component";
import LoginPage from "./pages/login/login.component";
import SpaceInfo from "./pages/space-info/space-info.component";
import Floor from "./pages/floor/floor.component";
import Area from "./pages/area/area.component";
import TimeTable from "./pages/timetable/timetable.component";
import NotificationPage from "./pages/notifications/notification.component";
import UserNotifications from "./pages/user-notifications/user-notifications.component";
import ManageSystem from "./pages/manage-system/manage-system.component";
import ManagePermissions from "./pages/manage-permissions/manage-permissions.component";
import EditSpacePage from "./pages/edit-space/edit-space.component";
import SystemInfo from "./pages/system-info/system-info.component";
import PrivateRoute from "./components/private-route/private-route.component";
import RegisterUser from "./pages/register-user/register-user.component";
import FilterSpacePage from "./pages/filter-space/filter-space.component";
import Reports from "./pages/reports/reports.component";
import DeleteReservation from "./pages/delete-reservation/delete-reservation.component";
import ManageReservationReason from "./pages/manage-reservation-reason/manage-reservation-reason.component";
import ConsultSpaceManager from "./pages/consult-space-manager/consult-space-manager.component";

import "./App.scss";

import NavbarCustom from "./components/navbar/navbar.component";

import API_ROUTE from "./utils/apiRoute";

const App = ({ history }) => {
  const dispatch = useDispatch();
  const employeeName = useSelector((state) => state.employee.employeeName);
  const employeeRole = useSelector((state) => state.employee.employeeRole);

  const [systemManager, setSystemManager] = useState(false);
  const [spaceManager, setSpaceManager] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const spaceNameRef = useState(React.createRef())[0];

  useEffect(() => {
    if (employeeRole === "system-manager") {
      setSystemManager(true);
    }
    if (employeeRole === "space-manager") {
      setSpaceManager(true);
    }
  }, [employeeRole]);

  const logout = () => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee/logout`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setIsAuthenticated(false);
            setSystemManager(false);
            setSpaceManager(false);
            history.push("/");
          }
        })
        .catch((err) => {
          alert("Something went wrong while logging out. Try Again.");
        });
    })();
  };

  const setAuthenticated = () => {
    setIsAuthenticated((prevState) => !prevState);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee`, {
          withCredentials: true,
        })
        .then((res) => {
          setIsAuthenticated(true);
          // this.props.setEmployeeRole(res.data.user.role);
          dispatch({ type: "SET_EMPLOYEE_ROLE", payload: res.data.user.role });
          dispatch({ type: "SET_EMPLOYEE_NAME", payload: res.data.user.name });
          setUserName(res.data.user.name);
          if (res.data.user.role === "system-manager") {
            setSystemManager(true);
          }
          if (res.data.user.role === "space-manager") {
            setSpaceManager(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  const DefaultRoutes = () => (
    <div className="App">
      <NavbarCustom
        isAuthenticated={isAuthenticated}
        systemManager={systemManager}
        spaceManager={spaceManager}
        employeeName={employeeName}
        logout={logout}
      />

      <div className="content">
        <Switch>
          {/* <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={HomePage}
              path="/"
              exact={true}
            /> */}
          {isAuthenticated ? (
            <Route exact path="/" component={HomePage} />
          ) : (
            <Route
              exact
              path="/"
              component={() => (
                <LoginPage
                  setAuthenticated={setAuthenticated}
                  setSystemManager={setSystemManager}
                  setUserName={setUserName}
                  setSpaceManager={setSpaceManager}
                />
              )}
            />
          )}

          {/* <Route exact path="/profile" component={() => <RegisterUser />} /> */}
          <Route
            exact
            path="/findSpace"
            component={() => <FilterSpacePage />}
          />
          {/* <Route
              exact
              path="/login"
              component={() => (
                <LoginPage
                  setAuthenticated={this.setAuthenticated}
                  setSystemManager={this.setSystemManager}
                  setUserName={this.setUserName}
                  setSpaceManager={this.setSpaceManager}
                />
              )}
            /> */}
          <Route
            exact
            path="/notifications"
            component={
              systemManager
                ? null
                : spaceManager
                ? NotificationPage
                : UserNotifications
            }
          />
          <Route
            exact
            path="/manage-system"
            component={
              systemManager ? ManageSystem : spaceManager ? EditSpacePage : null
            }
          />
          <Route
            exact
            path="/permissions"
            component={systemManager ? ManagePermissions : null}
          />

          <Route
            exact
            path="/spreadsheets"
            component={spaceManager ? Reports : null}
          />

          <Route
            exact
            path="/delete-reservation"
            component={systemManager ? DeleteReservation : null}
          />

          <Route
            exact
            path="/reservationReasons"
            component={systemManager ? ManageReservationReason : null}
          />

          <Route
            exact
            path="/myspaces"
            component={systemManager ? ManageReservationReason : null}
            component={
              systemManager || spaceManager
                ? () => <ConsultSpaceManager isAdmin={systemManager} />
                : null
            }
          />

          {/* <Route exact path="/edit-space" component={EditSpacePage} /> */}
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/info" component={SystemInfo} />
          <Route exact path="/floor/:floorId" component={Floor} />
          <Route exact path="/floor/:floorId/area/:areaId" component={Area} />
          <Route
            exact
            path="/floor/:floorId/area/:areaId/spaceinfo/:spaceinfoId"
            component={SpaceInfo}
          />
          <Route
            exact
            path="/floor/:floorId/area/:areaId/timetable/:timetableId"
            component={TimeTable}
          />
          {/* <Route
            path="*"
            component={() => {
              return <Redirect to="/notfound" />;
            }}
          /> */}
        </Switch>
      </div>
    </div>
  );

  return (
    <Switch>
      <Route component={NotFound} path="/notfound" />
      <Route component={DefaultRoutes} />
    </Switch>
  );
};

export default withRouter(App);

