import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import useModal from '../../../utils/useModal'
import Select from "react-select";
import {
  customInvalidStyle,
  customValidatedStyle,
  customStyles,
} from "../../../utils/react-select.custom-styles";
import "./create-space-modal.styles.scss";

import API_ROUTE from "../../../utils/apiRoute";
import ActionFeedbackModal from "../../action-feedback-modal/action-feedback-modal.component";

const CreateSpaceModal = ({ isShowing, toggle }) => {
  const [validated, setValidated] = useState(false);
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();
  const [titleText, setTitleText] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [allTypesOptions, setAllTypesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const [
    allSumariosSpaceNameOptions,
    setAllSumariosSpaceNameOptions,
  ] = useState([]);

  const nameRef = useState(React.createRef())[0];
  const numberRef = useState(React.createRef())[0];
  const initialsRef = useState(React.createRef())[0];
  const descriptionRef = useState(React.createRef())[0];
  const locationRef = useState(React.createRef())[0];
  const phoneNumberRef = useState(React.createRef())[0];
  const numberOfDoorsRef = useState(React.createRef())[0];
  const capacityRef = useState(React.createRef())[0];
  const coordinatesRef = useState(React.createRef())[0];

  const [selectedResourcesObj, setSelectedResourcesObj] = useState("");

  const [typeId, setTypeId] = useState(null);
  const [areaId, setAreaId] = useState(null);
  const [sumariosSpaces, setSumariosSpaces] = useState(null);

  const [allSpaceManagers, setAllSpaceManagers] = useState(null);
  const [selectedSpaceManager, setSelectedSpaceManager] = useState(null);

  const [allEmployees, setAllEmployees] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [selectedTechnicians, setSelectedTechnicians] = useState(null);

  const [isMultiSelectValid, setIsMultiSelectValid] = useState("null");

  const handleChangeSumarios = (selected) => {
    setSumariosSpaces(selected);
  };

  const handleEmployeeChange = (selected) => {
    setSelectedEmployee(selected);
  };

  const handleSpaceManagerChange = (selected) => {
    // if form submission was tried and there's a selected value then
    if (selected && isMultiSelectValid === "false") {
      setIsMultiSelectValid("true");
    }
    setSelectedSpaceManager(selected);
  };

  const handleChangeTypeId = (selected) => {
    setSelectedType(selected);
    setTypeId(selected.value);
  };

  const handleChangeAreaId = (selected) => {
    setSelectedArea(selected);
    setAreaId(selected.value);
  };

  const handleChangeResources = (selected) => {
    setSelectedResourcesObj(selected);
  };

  const handleChangeTechnicians = (selected) => {
    setSelectedTechnicians(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // validates Select Space Manager Input
    if (!selectedSpaceManager) {
      setIsMultiSelectValid("false");
    }

    if (selectedSpaceManager !== null && form.checkValidity() === true) {
      let resources = [];
      let technicians = [];
      let sumariosSpacesFormatted = [];
      let spaceManagersFormatted = [];

      if (selectedResourcesObj && selectedResourcesObj.length > 0)
        resources = selectedResourcesObj.map((resource) => resource.value);

      if (selectedTechnicians && selectedTechnicians.length > 0)
        technicians = selectedTechnicians.map((technician) => technician.value);

      if (sumariosSpaces && sumariosSpaces.length > 0)
        sumariosSpacesFormatted = sumariosSpaces.map(
          (sumariosSpace) => sumariosSpace.value
        );

      spaceManagersFormatted = selectedSpaceManager.map(
        (spaceManager) => spaceManager.value
      );

      const dataToSend = {
        name: nameRef.current.value,
        number: numberRef.current.value,
        initials: initialsRef.current.value,
        description: descriptionRef.current.value,
        location: locationRef.current.value,
        phoneNumber: phoneNumberRef.current.value,
        numberOfDoors: numberOfDoorsRef.current.value,
        capacity: capacityRef.current.value,
        coordinates: coordinatesRef.current.value,
        spaceSumariosId: sumariosSpacesFormatted,
        typeId,
        areaId,
        resources,
        technicians,
        spaceManagers: spaceManagersFormatted,
        responsibleId: selectedEmployee.value,
      };

      (async () => {
        await axios
          .post(`${API_ROUTE}space`, dataToSend, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Espaço criado com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedbackModal();
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
            setIsActionCompleted(false);
            toggleActionFeedbackModal();
          });
      })();
    } else {
      event.stopPropagation();
    }

    setValidated(true);
  };

  // get all Types and all Areas to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}type`, {
          withCredentials: true,
        })
        .then((res) => {
          const allTypes = res.data.data.allTypes;

          const tmpAllTypesOptions = allTypes.map((type) => {
            return {
              value: type._id,
              label: type.name,
            };
          });
          setSelectedType(tmpAllTypesOptions[0]);
          setTypeId(tmpAllTypesOptions[0].value);
          setAllTypesOptions(tmpAllTypesOptions);
        });

      await axios
        .get(`${API_ROUTE}area`, {
          withCredentials: true,
        })
        .then((res) => {
          const allAreas = res.data.data.allAreas;
          const tmpAllAreasOptions = allAreas.map((area) => {
            return {
              value: area._id,
              label: area.name,
            };
          });
          setAreaId(tmpAllAreasOptions[0].value);
          setSelectedArea(tmpAllAreasOptions[0]);
          setAreasOptions(tmpAllAreasOptions);
        });

      await axios
        .get(`${API_ROUTE}resource`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllResources = res.data.data.allResources.map((resource) => {
            return {
              value: resource._id,
              label: resource.name,
              description: resource.description,
            };
          });
          if (tmpAllResources.length > 0) {
            setAllResources(tmpAllResources);
          }
        });

      // I have to change this for the actual API REQUEST
      await axios
        .get(`${API_ROUTE}space/sumarios/`, {
          withCredentials: true,
        })
        .then((res) => {
          const fakeData = res.data.data.sumariosSpaces;

          const FAKE_SPACE_DATA = fakeData.map((sumariosSpace) => {
            return {
              value: sumariosSpace.codSala,
              label: sumariosSpace.nome,
            };
          });

          setAllSumariosSpaceNameOptions(FAKE_SPACE_DATA);
        });

      // get users
      await axios
        .get(`${API_ROUTE}employee/getAll/`, {
          withCredentials: true,
        })
        .then((res) => {
          const allEmployees = res.data.data.employees;

          const allEmployeesOptions = allEmployees.map((employee) => {
            return {
              value: employee._id,
              label: employee.name,
            };
          });

          if (allEmployeesOptions.length > 0) {
            setSelectedEmployee(allEmployeesOptions[0]);
          }
          setAllEmployees(allEmployeesOptions);

          const spaceManagers = allEmployees
            .filter((employee) => employee.role === "space-manager")
            .map((spaceManager) => {
              return { value: spaceManager._id, label: spaceManager.name };
            });

          setAllSpaceManagers(spaceManagers);
        });
    })();
  }, []);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Criar Espaço</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Área</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de uma área"
                    options={areasOptions}
                    className="react-select"
                    value={selectedArea}
                    onChange={handleChangeAreaId}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome do Espaço"
                    ref={nameRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um nome!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Tipo</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o tipo do espaço"
                    options={allTypesOptions}
                    value={selectedType}
                    onChange={handleChangeTypeId}
                    styles={customStyles}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Gestor do Espaço </Form.Label>
                  <Select
                    styles={
                      isMultiSelectValid === "true"
                        ? customValidatedStyle
                        : isMultiSelectValid === "false"
                        ? customInvalidStyle
                        : customStyles
                    }
                    isMulti
                    placeholder="Selecione ou digite o nome do gestor do espaço"
                    options={allSpaceManagers}
                    value={selectedSpaceManager}
                    onChange={handleSpaceManagerChange}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Responsável</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome do responsável"
                    options={allEmployees}
                    value={selectedEmployee}
                    onChange={handleEmployeeChange}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Técnicos</Form.Label>
                  <Select
                    isMulti
                    placeholder="Selecione ou digite o(s) nome(s) do(s) técnico(s)"
                    options={allEmployees}
                    onChange={handleChangeTechnicians}
                    value={selectedTechnicians}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>
                    Nome do Espaço nos Sumários (se houver)
                  </Form.Label>
                  <Select
                    isMulti
                    isClearable={true}
                    placeholder="Selecione ou digite o nome do espaço nos sumários"
                    options={allSumariosSpaceNameOptions}
                    value={sumariosSpaces}
                    onChange={handleChangeSumarios}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Número do Espaço"
                    ref={numberRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um número!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Abreviatura</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Abreviatura do Espaço"
                    ref={initialsRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma abreviatura!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Descrição do Espaço"
                    ref={descriptionRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma descrição!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Localização</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Localização do Espaço"
                    ref={locationRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma Localização!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Extensão Telefónica</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Extensão Telefónica"
                    ref={phoneNumberRef}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Número de Portas</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Número de Portas"
                    ref={numberOfDoorsRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um número de portas!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Capacidade</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Capacidade do Espaço"
                    ref={capacityRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma Capacidade!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Recursos</Form.Label>
                  <Select
                    isMulti
                    placeholder="Selecione ou digite o tipo do espaço"
                    options={allResources}
                    onChange={handleChangeResources}
                    value={selectedResourcesObj}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Coordernadas</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coordenadas do Espaço"
                    required
                    ref={coordinatesRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de Coordernadas!
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ActionFeedbackModal
            isShowing={isShowingActionFeedbackModal}
            toggle={toggleActionFeedbackModal}
            isActionCompleted={isActionCompleted}
            titleText={titleText}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CreateSpaceModal;
