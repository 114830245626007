import React from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";

const ListSpacesModal = ({ isShowing, toggle, allSpaces }) => {
  const renderSpaces = () => {
    if (!allSpaces.length) return;

    // sort names alphabetically
    allSpaces.sort((a, b) => a.name.localeCompare(b.name));
    return React.Children.toArray(
      allSpaces.map((space) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: ".3rem",
              }}
            >
              {space.name}
            </div>
            <hr />
          </>
        );
      })
    );
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Espaços onde se é gestor</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {allSpaces.length === 0 ? (
                <h6>Não há nada para listar.</h6>
              ) : null}
              {renderSpaces()}
            </Modal.Body>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ListSpacesModal;
