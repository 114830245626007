import React, { useState } from "react";

import useModal from '../../utils/useModal'
import SelectEditArea from "./select-edit-area.component";
import EditAreaModal from "./edit-area-modal.component";

import ActionFeedbackModal from "../action-feedback-modal/action-feedback-modal.component";
import ListFloorAreaSpaceModal from "../list-floor-area-space/list-floor-area-space-modal.component";

const EditArea = ({
  isShowing,
  toggle,
  setIsEdit,
  toggleListArea,
  isShowingListArea,
  areaSelectType,
}) => {
  const { isShowing: isShowingEditAreaModal, toggle: toggleEditAreaModal } = useModal();
  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();

  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [floorId, setFloorId] = useState(null);
  const [areaId, setAreaId] = useState(null);

  const [titleText, setTitleText] = useState(null);

  return (
    <>
      <SelectEditArea
        isShowing={isShowing}
        setAreaId={setAreaId}
        areaId={areaId}
        toggle={toggle}
        toggleEditFloorModal={toggleEditAreaModal}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
        areaSelectType={areaSelectType}
        toggleListArea={toggleListArea}
        setFloorId={setFloorId}
        floorId={floorId}
      />

      <ListFloorAreaSpaceModal
        toggle={toggleListArea}
        setIsEdit={setIsEdit}
        isShowing={isShowingListArea}
        setDocumentId={setAreaId}
        setIsActionCompleted={setIsActionCompleted}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        toggleEdit={toggleEditAreaModal}
        listType="area"
        documentId={floorId}
      />

      <EditAreaModal
        isShowing={isShowingEditAreaModal}
        toggle={toggleEditAreaModal}
        areaId={areaId}
        setTitleText={setTitleText}
        toggleActionFeedback={toggleActionFeedbackModal}
        setIsActionCompleted={setIsActionCompleted}
      />
      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        isActionCompleted={isActionCompleted}
        titleText={titleText}
      />
    </>
  );
};

export default EditArea;
