import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import spaceReducer from "./spaces/space.reducer";
import employeeReducer from "./employee/employee.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["space"]
};

const rootReducer = combineReducers({
  space: spaceReducer,
  employee: employeeReducer
});

export default persistReducer(persistConfig, rootReducer);
