import React from "react";

import { Form, Col, InputGroup, FormControl } from "react-bootstrap";

import "./custom-form.styles.scss";

class CustomForm extends React.Component {
  render() {
    const {
      number,
      numberOfDoors,
      description,
      location,
      phoneNumber,
      capacity,
      initials,
      resourcesObj,
      responsibleObj,
      technicians,
      managers,
    } = this.props;

    const renderResources = () => {
      return resourcesObj.map((resource) => {
        return (
          <>
            <div style={{ marginLeft: "3%", marginTop: "1%" }}>
              {resource.name}
            </div>
          </>
        );
      });
    };

    const renderTechniciansOrManagers = (array, type) => {
      if (array.length > 0) {
        return (
          <Form.Row>
            <Form.Group as={Col} controlId="capacity">
              <Form.Label>{type}</Form.Label>

              {array.map((tech) => {
                return (
                  <>
                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Nome</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl disabled defaultValue={tech.name} />
                    </InputGroup>

                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Email</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl disabled defaultValue={tech.email} />
                    </InputGroup>
                  </>
                );
              })}
            </Form.Group>
          </Form.Row>
        );
      }

      return;
    };

    return (
      <div>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="spaceNumber">
              <Form.Label>Número</Form.Label>
              <Form.Control disabled readOnly defaultValue={number} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="portNumber">
              <Form.Label>Abreviatura</Form.Label>
              <Form.Control disabled readOnly defaultValue={initials} />
            </Form.Group>

            <Form.Group as={Col} controlId="extensionPhone">
              <Form.Label>Extensão Telefónica</Form.Label>
              <Form.Control disabled readOnly defaultValue={phoneNumber} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="location">
              <Form.Label>Localização</Form.Label>
              <Form.Control disabled as="textarea" defaultValue={location} />
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="description">
            <Form.Label>Descrição</Form.Label>
            <Form.Control disabled as="textarea" defaultValue={description} />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} controlId="capacity">
              <Form.Label>Capacidade</Form.Label>
              <Form.Control disabled readOnly defaultValue={capacity} />
            </Form.Group>

            <Form.Group as={Col} controlId="numberOfDoors">
              <Form.Label>Número de Portas</Form.Label>
              <Form.Control disabled readOnly defaultValue={numberOfDoors} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="capacity">
              <Form.Label>Responsável</Form.Label>

              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Nome</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl disabled defaultValue={responsibleObj.name} />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Email</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl disabled defaultValue={responsibleObj.email} />
              </InputGroup>
            </Form.Group>
          </Form.Row>

          {renderTechniciansOrManagers(managers, "Gestore(s) de Espaço")}
          {renderTechniciansOrManagers(technicians, "Técnico(s)")}

          <Form.Label>Recursos</Form.Label>
          <div className="resources">
            {resourcesObj.length === 0 ? (
              <p style={{ marginLeft: "3%", marginTop: "2%" }}>
                Não há recursos neste espaço.
              </p>
            ) : (
              renderResources()
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default CustomForm;
