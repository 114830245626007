const INITAL_STATE = {
  floorId: null,
  subspaceId: null,
  elementariesSpaces: [],
  selectedDate: [],
};

const spaceReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case "SET_FLOOR_ID":
      return {
        ...state,
        floorId: action.payload,
      };
    case "SET_SUBSPACE_ID":
      return {
        ...state,
        subspaceId: action.payload,
      };
    case "SET_ELEMENTARIES_ID":
      return {
        ...state,
        elementariesSpaces: action.payload,
      };
    case "SET_DATE":
      return {
        ...state,
        selectedDate: action.payload,
      };

    default:
      return state;
  }
};

export default spaceReducer;
